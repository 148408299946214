import React from 'react';
import './siteTabs.css';
import { FaSearch } from 'react-icons/fa';

const Research = () => {
    return(
        <>
            <div className='dashboardPage'>
                <div className='analyticResearch'>
                    <div className='topRes'>
                        <h4>Explore what viewers are searching for on BiiView</h4>
                        <p class="Tab-sofia-p">Use this tool to research some of the top searches from viewers on your channel and across YouTube in the last 28 days</p>
                    </div>
                    <div className='researchForm'>
                        <span className='researchIcon'><FaSearch/></span>
                        <input type='text' placeholder='Enter a search term or topic'></input>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Research