import React from 'react';
import Banner from './Banner/Banner'
import Explore from './Explore/Explore'
import Works from './Works/Works'
import Feature from './Feature/Feature';

const Home = () => {
    return(
        <>
            <Banner/>
            <Explore/>
            <Works/>
            <Feature/>
        </>
    )
}
export default Home