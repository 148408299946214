import React from 'react'
import '../Models.css'
import { Link } from 'react-router-dom'
const AdvanceSettings = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                <div className='advanceSetting'>
                    <div className='general mb-0'>
                        <h4>Audience</h4>
                        <p>Simplify your workflow by selecting a channel setting. If you skip this question, you'll be required to identify each video on your channel that's made for kids. This setting will affect existing and future videos. Settings for individual videos will override the channel setting.</p>
                    </div>
                    <div className='laws mb-0'>
                        <div>
                            <h5>Do you want to set your channel as made for kids?</h5>
                            <p>Regardless of your location, you're legally required to comply with the Children's Online Privacy Protection Act (COPPA) and/or other laws. You're required to tell us whether your videos are made for kids.</p>
                        </div>
                        <ul>
                            <li><p>Yes, set this channel as made for kids. I always upload content that’s made for kids.</p></li>
                            <li><p>No, set this channel as not made for kids. I never upload content that’s made for kids.</p></li>
                            <li><p>I want to review this setting for every video.</p></li>
                        </ul>
                        <div className='linkAccount me-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div><p>Google Ads account linking</p></div>
                            <Link class="accountBtn link">LINK ACCOUNT</Link>
                        </div>
                            <p>Link your YouTube channel to a Google Ads account to let the linked Google Ads account run ads based on interactions with your channel’s videos and to access insights from your channel’s videos.</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='d-flex justify-content-start align-items-start'>
                                <div className="form-check checkTable me-2 px-0 modalCheck mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <h4 className='check-H4 mb-0'>Automatic captions</h4>
                            </div>
                            <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-start mb-3'>
                                <div className="form-check checkTable me-2 px-0 modalCheck">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <h4 className='check-H4 mb-0'>Advertisment</h4>
                            </div>
                            <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-start mb-3'>
                                <div className="form-check checkTable me-2 px-0 modalCheck">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                                <h4 className='check-H4 mb-0'>Clips</h4>
                            </div>
                            <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        </div>
                    </div>
                    <div className='manageAccounts'>
                        <h4 className='mb-3'>Automatic captions</h4>
                        <Link className='blueLink'>manage your account</Link>
                        <Link className='blueLink'>delete your account</Link>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default AdvanceSettings