import React from 'react';
import './Footer.css'
import { Container } from 'react-bootstrap'
import { Link } from "react-router-dom";
import logo from '../../../assets/images/logo.png'
import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa';


const Footer = () => {
    return (
        <>
            <div className='wrapFooter'>
                <div className='footer'>
                    <div className='bar'></div>
                    <Container fluid className='customBox'>
                        <div className='footerTop'>
                            <div className='footerLogo'>
                                <img className='img-fluid' src={logo} alt='Biiview'></img>
                                <p className='outfit-p'>Donec dapibus mauris id odio ornare tempus.</p>
                            </div>
                            <div className='socialIcon'>
                                <ul className='p-0'>
                                    <li><Link><FaFacebookF /></Link></li>
                                    <li><Link><FaTwitter /></Link></li>
                                    <li><Link><FaInstagram /></Link></li>
                                    <li><Link><FaPinterest /></Link></li>
                                    <li><Link><FaYoutube /></Link></li>
                                </ul>
                            </div>
                            <div className='footerLink'>
                                <ul className='p-0'>
                                    <li><Link to="/" className='link'>home</Link></li>
                                    <li><Link to="/about" className='link'>About Us</Link></li>
                                    <li><Link to="/blogs" className='link'>blog</Link></li>
                                    <li><Link to="/contact" className='link'>Contact Us</Link></li>
                                    <li><Link to="/terms-and-conditions" className='link'>Terms & Conditions</Link></li>
                                    <li><Link to="/privacy-policy" className='link'>Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='footerBtm'>
                            <span className='circle'></span>
                            <p className='copyright'>© 2022 BiiView. All rights reserved</p>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}
export default Footer