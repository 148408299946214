import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from './routes'

const App = () => {
    return (
        <>
            <Router>
                <Routes>
                    {routes.map((route, index) => (
                        <Route exact={route.exact} path={route.path} element={<route.layout title={route.name}> 

                        <route.component /> </route.layout>} key={index} />
                    ))}
                </Routes>
            </Router>
        </>
    )
}

export default App