import React from 'react';
import '../Forms.css'
// import './CardDetails.css'
import {Container, Form } from 'react-bootstrap'
import formLogo from '../../../../assets/images/logo.png'
import {useNavigate, Link} from 'react-router-dom'
const CardDetails = () => {
      const navigate = useNavigate();
      const navigateToDashboardMember = () => {
          navigate('/member-dashboard');
        };
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                            <div className='wrapForm'>
                                <Form className='formStyle'>
                                    <div className='formHeader'> <h2>Card Details</h2> </div>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Name On Card</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="John Smith"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Card Number</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0000 0000 0000 0000"></input>
                                    </Form.Group>
                                   <div className='d-flex'>
                                   <Form.Group className='formGroup me-3 w-75' controlId="formBasicEmail">
                                        <Form.Label>Expiry Date</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Expiry Date (MM/YY)"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>CVV</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="912"></input>
                                    </Form.Group>
                                   </div>
                                    <div className='theme-capcha'>
                                        <button className='orange-btn text-uppercase' onClick={navigateToDashboardMember}>PAY NOW</button>
                                    </div>
                                   
                                </Form>
                                </div>
                        </Container>
                    </div>
            </section>
        </>
    )
}
export default CardDetails