import React, { useEffect } from 'react'

const Auth = ({ title, children }) => {

    useEffect(() => {
        if (title)
            document.title = title;
        else
            document.title = "BIIVIEW";
    }, [title]);

    return (
        <>
            {children}
        </>
    )
}

export default Auth