import React from 'react'
import '../Models.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import UploadBasicInfo from './UploadBasicInfo '
import UploadAdvanceSettings from './UploadAdvanceSettings'
const UploadDefault = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabs" >
                    <Tabs>
                        <TabList>
                            <Tab>Basic Info</Tab>
                            <Tab>Advance Settings</Tab>
                        </TabList>
                        <TabPanel>
                            <UploadBasicInfo />
                        </TabPanel>
                        <TabPanel>
                            <UploadAdvanceSettings/>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default UploadDefault