import React from 'react'
import './PrivacyPolicy.css'
import Information from './Information'
import PolicyBanner from './PolicyBanner'

const Index = () => {
  return (
    <>
        <PolicyBanner />
        <Information />
    </>
  )
}

export default Index