import React from 'react'
import '../Models.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { TiArrowSortedDown } from "react-icons/ti"
import { Modal } from 'react-bootstrap'
import VideoSubtitlePage from '../../VideoSubtitlePage/VideoSubtitlePage'
const VideiSubtitles = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const [videoSubtitlesPage, setVideoSubtitlesPage] = useState(false)
    return(
        <>
            <div className='modalPage'>
                <div className="modal show" >
                    <Modal show={show} onHide={handleClose} className="confirmModel settingModel">
                        <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>Video Subtitles</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='py-lg-4 py-3'>
                            <div className='general mb-2'>
                                <h4>Default Units</h4>
                                <div className='modalSelect'>
                                    <select className="form-select" aria-label="Set language">
                                        <option selected>USD - US Dollar</option>
                                        <option value="1">USD - US Dollar1</option>
                                        <option value="2">USD - US Dollar2</option>
                                        <option value="3">USD - US Dollar2</option>
                                    </select>
                                    <span><TiArrowSortedDown/></span>
                                </div>
                            </div>
                            <div className='mb-5'>
                                <div class="d-flex justify-content-start align-items-start">
                                    <div class="form-check checkTable me-2 px-0 modalCheck">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault" value="" />
                                    </div>
                                    <h4>Make this default for my channel</h4>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='d-flex align-items-center'>
                                <button class="grey-btn text-uppercase me-lg-3 me-2 mt-0" onClick={handleClose}>close</button>
                                <Link to='/video-subtitles' class="m-0 p-0" ><button class="orange-btn text-uppercase mx-auto mt-0" onClick={() => setVideoSubtitlesPage(!videoSubtitlesPage)}>confirm</button></Link>        
                            </div>
                        </Modal.Footer>
                    </Modal>
                    {videoSubtitlesPage && <VideoSubtitlePage/>}
                </div>
            </div>
        </>
    )
}
export default VideiSubtitles