import React from 'react';
import './Banner.css'
import {Container, Row, Col} from 'react-bootstrap'
// import { Player } from 'video-react';
// import VideoLooper from 'react-video-looper'
// import AutoplayVideo from 'wtc-autoplay-video';
import bannerVideo from '../../../../assets/videos/bannerVideo.mp4'
import {useNavigate} from 'react-router-dom'
const Banner = () => {
    const navigate = useNavigate();
    const navigateToLogin = () => {
        navigate('/login');
    };
    const navigateToMember = () => {
        navigate('/member');
    };
    return(
        <>
            <section className='banner'>
                <div className='overlay'></div>
                <div className='player-wrapper'>
                    <video class="autoPlay-video__video"  autoPlay loop muted playsInline >
                        <source src={bannerVideo} type="video/mp4" />
                    </video>
                   
                </div>
                <Container fluid className='customBox pe-0'>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className='mianBanner'>
                                <div className='wrapBanner'>
                                    <h1>Software <span className='theme'>Scans & Processes</span> the Video for Learned 
                                    Images From its <span className='theme'>Database</span>.</h1>
                                  
                                     <p className='sofia-p'>Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique
                                     nunc ut volutpat lectus.</p>
                                </div>
                                <div className='wrapBannerBtn'>
                                    <div><button className='orange-btn text-uppercase' onClick={navigateToMember}>
                                    BECOME A MEMBER</button></div>
                                    <div><button onClick={navigateToLogin} className='black-btn theme-btn 
                                    text-uppercase'>LOGIN</button></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Banner