import React from 'react';
import '../Forms.css'
import {Container, Form } from 'react-bootstrap'
import formLogo from '../../../../assets/images/formLogo.png'
import {useNavigate, Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
const MemberLogin = () => {
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const navigate = useNavigate();
    const navigateToPackage = () => {
    // 👇️ navigate to /PACKAGE
          navigate('/package');
    };

    const navigateToForgot = () => {
    // 👇️ navigate to /Forget
        navigate('/forgot');
    };
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                            <div className='wrapForm'>
                                <Form className='formStyle'>
                                    <div className='formHeader'>
                                        <h2>Member Login</h2>
                                        <div className='d-flex align-items-end justify-content-center'>
                                        <p className='mb-0'>BECOME MEMBER?</p><Link to='/member'  className='link themeLink ms-2'>REGISTER</Link></div>
                                    </div>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email Address"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Password</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Your Password"></input>
                                    </Form.Group>
                                    <div className='theme-capcha'>
                                        <ReCAPTCHA
                                            sitekey="Your client site key"
                                            onChange={onChange}
                                        />
                                        <Link to='/member-dashboard' className='theme-btn orange-btn text-uppercase end-mb' onClick={navigateToPackage}>Login</Link>
                                    </div>
                                    <div className='d-flex align-items-end justify-content-center text-uppercase'><p className='mb-0 link outer-p' onClick={navigateToForgot}>FORGOT YOUR PASSWORD?</p></div>
                                </Form>
                            </div>
                        </Container>
                    </div>
            </section>
        </>
    )
}
export default MemberLogin