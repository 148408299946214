import React from 'react'
import {Form, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../Models.css'

import card1 from '../../../../../assets/images/card1.png'
import { HiOutlineClipboardDocument } from "react-icons/hi2"
const Details  = () => {

    return(
        <>
            <div className='modalPage'>
            
                <div className=" themeModal" >
                    <div className='seletFile modalBox'>           
                        <Row>
                            <Col md={8} className='mb-md-0 mb-3'> 
                                <h4 className='modal-H4'>Details</h4>
                                <div className='fileGroup'>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'>Title (required)</p>
                                        <div className='fileBlock'>
                                            <p>Video 20221205071229 Soldier Major Tank</p>
                                        </div>
                                    </div>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'>Description</p>
                                        <Form.Group className='w-100' as="textarea" rows={3} placeholder='Tell us something about your video….'>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='thumbnail'>
                                    <h4 className='modal-H4'>Frames</h4>
                                    <p className='fileLabel opacity-40'>Select the frame and see the recognized objects from the video or edit their labels.</p>
                                    <div className='thumbnailImg'>
                                        <div className='videoImg'><img className='img-fluid' src={card1} alt='biiview'></img></div>
                                        <div className='videoImg'><img className='img-fluid' src={card1} alt='biiview'></img></div>
                                        <div className='videoImg'></div>
                                        <div className='videoImg'></div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='videoLinkBox'>
                                    <div className='videoLinkImg'>
                                        <figure className='mb-0'><img className='img-fluid' src={card1} alt='Video'></img></figure>
                                    </div>
                                    <div className='vidoDetails'>
                                        <h5 className='ellipse'>Video 20221205071229 Soldier Major Tank</h5>
                                        <p className='mb-0'>Video Link:</p>
                                        <div className='clipboardDiv'>
                                        <Link to='/' className='videoLink ellipse'>https://www.biiview.com/Video2022</Link>
                                        <span> <HiOutlineClipboardDocument/> </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>  
                    </div>
                </div>
            </div>
            
        </>
    )
}
export default Details