import React from 'react'
import { Container } from 'react-bootstrap'
import team1 from '../../../../assets/images/team1.png'
import team2 from '../../../../assets/images/team2.png'
import team3 from '../../../../assets/images/team3.png'
import team4 from '../../../../assets/images/team4.png'
import team5 from '../../../../assets/images/team5.png'
import team6 from '../../../../assets/images/team6.png'
import team7 from '../../../../assets/images/team7.png'
import team8 from '../../../../assets/images/team8.png'
import team9 from '../../../../assets/images/team9.png'
import team10 from '../../../../assets/images/team10.png'


const Team = () => {
  return (
    <>
      <section className='team-sec'>
        <Container className='customBox'>
          <h1>Meet our <h1 className='theme'>Amazing Team</h1></h1>
          <div className='teams row d-flex flex-wrap'>
            <div className='team'>
              <div className='img'>
                <img src={team1} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Nancy Lee</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team2} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Jacob Riley</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team3} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Janet Pierce</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team4} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Larry Nelson</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team5} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Jose Dean</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team6} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Tom Garrett</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team7} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Nancy Stanley</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team8} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Roy Porter</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team9} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Francine Wells</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
            <div className='team'>
              <div className='img'>
                <img src={team10} className="img-fluid w-100 h-100" alt='team' />
              </div>
              <p className='team-name mb-2'>Alex Weber</p>
              <p className='team-designation mb-0'>Designation</p>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Team