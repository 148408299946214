import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Details = () => {
    return (
        <>
            <section className='details-sec  pb-0'>
                <Container className='customBox'>
                    <p>Proin ex ipsum, facilisis id tincidunt sed, vulputate in lacus. Donec pharetra faucibus leo, vitae vestibulum leo scelerisque eu. Nam enim dolor, porta at ex ut, eleifend tristique metus. Integer sit amet auctor ligula, vel dictum orci. Nam feugiat interdum nisl. Ut id velit ac ante maximus gravida.</p>
                    <Row>
                        <Col lg={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src='./images/terms.svg' alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Terms</h2>
                                    <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper id. Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src='./images/use-license.svg' alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Use license</h2>
                                    <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper id. Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src='./images/disclaimer.svg' alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Disclaimer</h2>
                                    <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper id. Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src='./images/limitations.svg' alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Limitations</h2>
                                    <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper id. Aliquam erat volutpat. Integer sit amet laoreet eros.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='all-others'>
                        <h1>All others</h1>
                        <div className='all-others-content'>
                            <h4>Links</h4>
                            <p>Aliquam erat volutpat. Integer sit amet laoreet eros. Etiam feugiat sit amet urna eget efficitur. Fusce mollis velit sapien, rutrum tincidunt dui hendrerit a. Nam lacus elit, faucibus id efficitur porttitor, tincidunt et est. Quisque eget lacus non orci ultricies fringilla quis tincidunt urn Integer sit amet laoreet eros. Etiam feugia.</p>
                            <h4>Modifications</h4>
                            <p>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellentesque.</p>
                            <h4>Product Download</h4>
                            <p>Product DownloadSed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada teger porttitor auctor eros nec pellentesque.</p>
                            <h4>Refund Policy</h4>
                            <p>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis. egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellentesque.</p>
                            <h4>Governing Law</h4>
                            <p className='governing-law-para'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis. egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies.</p>
                            <div className='ul-list'>
                                <ul>
                                    <li>Aliquam erat volutpat. Integer sit amet laoreet eros</li>
                                    <li>Etiam feugiat sit amet urna eget efficitur</li>
                                    <li>Rutrum tincidunt dui hendrerit wam lacus eli faucibus id efficitur</li>
                                    <li>Hincidunt et est isque eget lacus non orci ultricies fringilla</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Details