import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const Public = ({ title, children }) => {

    useEffect(() => {
        if (title)
            document.title = title;
        else
            document.title = "BIIVIEW";
    }, [title]);

    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    )
}

export default Public