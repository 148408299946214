import React from 'react'

import { Table } from 'react-bootstrap'
import '../Subtitles/Subtitles.css'
import video1 from '../../../../assets/images/card5.png'

const IdentifiedObjects = () => {

    return (
        <>
            <div className='dashboardPage'>
                <div className='content px40'>
                    <div className='themeTable'>
                        <Table resposinve>
                            <thead>
                                <tr>
                                    <th><p>Video</p></th>
                                    <th><p>Category</p></th>
                                    <th><p>Frames</p></th>
                                    <th><p>Linkes</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='videoInfo videoPara'>
                                            <div className='videoImg'>
                                                <img className='img-fluid' src={video1} alt='Video'></img>
                                            </div>

                                            <div>
                                                <p className='analytic-P'>Video 20221205071229 Soldier</p>
                                                <p className='light-p fw3'>A turpis egestas. Nunc sagittis consectetur velit, ac gravida nunc gravida et. Vestibulum at eros imperdiet, volutpat nunc vitae</p>

                                            </div>
                                        </div>
                                    </td>
                                    <td><p>Food & Drink</p></td>
                                    <td><p>None</p></td>
                                    <td><p>www.google.com</p></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='videoInfo videoPara'>
                                            <div className='videoImg'>
                                                <img className='img-fluid' src={video1} alt='Video'></img>
                                            </div>

                                            <div>
                                                <p className='analytic-P'>Video 20221205071229 Soldier</p>
                                                <p className='light-p fw3'>A turpis egestas. Nunc sagittis consectetur velit, ac gravida nunc gravida et. Vestibulum at eros imperdiet, volutpat nunc vitae</p>

                                            </div>
                                        </div>
                                    </td>
                                    <td><p>Food & Drink</p></td>
                                    <td><p>None</p></td>
                                    <td><p>www.google.com</p></td>
                                </tr>
                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        </>
    )
}
export default IdentifiedObjects