import React from 'react';
import '../Forms.css'
import './Package.css'
import {Container, Row, Col } from 'react-bootstrap'
import formLogo from '../../../../assets/images/formLogo.png'
import {useNavigate, Link} from 'react-router-dom'
const Package = () => {
      const navigate = useNavigate();
      const navigateToCardDetails = () => {
          navigate('/card-details');
        };
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                            <div className='wrapForm'>
                                <div className='formStyle package'>
                                    <div className='formHeader  border-0'>
                                        <h2>Choose your Package</h2>
                                    </div>
                                    <Row>
                                        <Col lg={4} md={6} className='mb-lg-0 mb-3'>
                                            <div className='package-card'>
                                                <div>
                                                    <p className='packageTitle'>FREE FOREVER</p>
                                                    <h3 className='packageH3'>$0<sub className='h3Sub'>/month</sub></h3>
                                                    <ul className='package-ul'>
                                                        <li><p>Can upload a short-length video (up to 15 min)</p></li>
                                                        <li><p>file size of the video</p></li>
                                                        <li><p>can be 500 MBs</p></li>
                                                        <li><p>Basic-level Object Detection</p></li>
                                                        <li><p>~70% Accuracy Rate</p></li>
                                                        <li><p>Short links creation</p></li>
                                                        <li><p>3 links per video</p></li>
                                                        <li><p>Can change the product links up to 0 times</p></li>
                                                    </ul>
                                                </div>
                                                <div><button className='orange-btn text-uppercase mt-0' onClick={navigateToCardDetails}>SELECT</button></div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6} className='mb-lg-0 mb-3'>
                                            <div className='package-card'>
                                                <div>
                                                    <p className='packageTitle'>INDIVIDUAL</p>
                                                    <ul className='packageCardHeader'>
                                                        <div>
                                                            <h3 className='packageH3'>$15<sub className='h3Sub'>/month</sub></h3>
                                                        </div>
                                                        <div>
                                                            <h3 className='packageH3'>$153<sub className='h3Sub'>/year</sub></h3>
                                                        </div>
                                                    </ul>
                                                    <ul className='package-ul'>
                                                        <li><p>Can upload a Medium-length video (up to 30 min)</p></li>
                                                        <li><p>Can upload up to 10 Videos</p></li>
                                                        <li><p>Max. file size of the video can be 1024 MBs</p></li>
                                                        <li><p>Normal Object Detection</p></li>
                                                        <li><p>~80% Accuracy Rate</p></li>
                                                        <li><p>Short links creation</p></li>
                                                        <li><p>10 links per video</p></li>
                                                        <li><p>Can change the product links up to 5 times</p></li>
                                                    </ul>
                                                </div>
                                                <div><button className='orange-btn text-uppercase mt-0' onClick={navigateToCardDetails}>SELECT</button></div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <div className='package-card'>
                                                <div>
                                                    <p className='packageTitle'>CORPORATE</p>
                                                    <ul className='packageCardHeader'>
                                                        <div>
                                                            <h3 className='packageH3'>$25<sub className='h3Sub'>/month</sub></h3>
                                                        </div>
                                                        <div>
                                                            <h3 className='packageH3'>$255<sub className='h3Sub'>/year</sub></h3>
                                                        </div>
                                                    </ul>
                                                    <ul className='package-ul'>
                                                        <li><p>Can upload long-length videos (up to 60 min)</p></li>
                                                        <li><p>Can upload up to 50 Videos</p></li>
                                                        <li><p>Max. file size of the video can be 5120 MBs</p></li>
                                                        <li><p>Professional-level Object Detection</p></li>
                                                        <li><p>~90% Accuracy Rate</p></li>
                                                        <li><p>Customizable Short links creation</p></li>
                                                        <li><p>20 links per video</p></li>
                                                        <li><p>Can change the product links up to 10 times</p></li>
                                                    </ul>
                                                </div>
                                                <div><button className='orange-btn text-uppercase mt-0' onClick={navigateToCardDetails}>SELECT</button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                  
                                </div>
                            </div>
                        </Container>
                    </div>
            </section>
        </>
    )
}
export default Package