//layouts
import Auth from './components/Frontend/Layouts/Auth';
import Dashboard from './components/Frontend/Layouts/Dashboard';
import Public from './components/Frontend/Layouts/Public';
import MemberDashboard from './components/Frontend/Layouts/MemberDashboard';

//components
import Home from './components/Frontend/Home/Home';
import Signup from './components/Frontend/Forms/Signup/Signup';
import Login from './components/Frontend/Forms/Login/Login';
import Member from './components/Frontend/Forms/Member/Member';
import Package from './components/Frontend/Forms/Package/Package';
import CardDetails from './components/Frontend/Forms/CardDetails/CardDetails';
import ForgotPass from './components/Frontend/Forms/ForgotPass/ForgotPass';
import MemberLogin from './components/Frontend/Forms/MemberLogin/MemberLogin';
import DashboardHome from './components/Frontend/DashboardHome/DashboardHome';
import ChannelAnalytics from './components/Frontend/Dashboard/ChannelAnalytics/ChannelAnalytics';
import DasboardPage from './components/Frontend/Dashboard/DasboardPage/DasboardPage';
import Content from './components/Frontend/Dashboard/Content/Content';
import Subtitles from './components/Frontend/Dashboard/Subtitles/Subtitles';
import Settings from './components/Frontend/Dashboard/Settings/Settings';
import About from './components/Frontend/Dashboard/About/About';
import Contact from './components/Frontend/Dashboard/Contact/Contact';
import TermsConditions from './components/Frontend/Dashboard/Tearms&Conditions';
import PrivacyPolicy from './components/Frontend/Dashboard/PrivacyPolicy';
import BlogDetails from './components/Frontend/Dashboard/BlogDetails';
import Blogs from './components/Frontend/Dashboard/Blogs';
import VideoSubtitlePage from './components/Frontend/Dashboard/VideoSubtitlePage/VideoSubtitlePage';
import VidoeAnalytics from './components/Frontend/Dashboard/Content/VidoeAnalytics/VidoeAnalytics';
import PackageBack from './components/Frontend/Forms/PackageBack/PackageBack';
import Audiance from './components/Frontend/Dashboard/TabComponents/Audiance';
import IdentifiedObjects from './components/Frontend/Dashboard/TabComponents/IdentifiedObjects';

const routes = [
  { path: "/", exact: true, name: "Home", layout: Public, component: Home },
  { path: "/signup", exact: true, name: "Sign Up", layout: Auth, component: Signup },
  { path: "/login", exact: true, name: "Login", layout: Auth, component: Login },
  { path: "/forgot", exact: true, name: "Forget Password", layout: Auth, component: ForgotPass },
  { path: "/member", exact: true, name: "Member", layout: Auth, component: Member },
  { path: "/member-login", exact: true, name: "Member Login", layout: Auth, component: MemberLogin },
  { path: "/package", exact: true, name: "Package", layout: Auth, component: Package },
  { path: "/back-package", exact: true, name: "PackageBack", layout: Auth, component: PackageBack },
  { path: "/card-details", exact: true, name: "Card Details", layout: Auth, component: CardDetails },
  { path: "/dashboard", exact: true, name: "Dashboard", layout: Dashboard, component: DashboardHome },
  { path: "/member-dashboard", exact: true, name: "Member Dashboard", layout: MemberDashboard, component: DasboardPage },
  { path: "/content", exact: true, name: "Content", layout: MemberDashboard, component: Content },
  { path: "/channel-analytics", exact: true, name: "Channel Analytics", layout: MemberDashboard, component: ChannelAnalytics },
  { path: "/subtitles", exact: true, name: "Subtitles", layout: MemberDashboard, component: Subtitles },
  { path: "/settings", exact: true, name: "Settings", layout: MemberDashboard, component: Settings },
  { path: "/about", exact: true, name: "About", layout: Public, component: About },
  { path: "/contact", exact: true, name: "Contact", layout: Public, component: Contact },
  { path: "/terms-and-conditions", exact: true, name: "Terms and Conditions", layout: Public, component: TermsConditions },
  { path: "/privacy-policy", exact: true, name: "Privacy Policy", layout: Public, component: PrivacyPolicy },
  { path: "/blog-details", exact: true, name: "Blog Details", layout: Public, component: BlogDetails },
  { path: "/blogs", exact: true, name: "Blogs", layout: Public, component: Blogs },
  { path: "/video-subtitles", exact: true, name: "Video Subtitle", layout: MemberDashboard, component: VideoSubtitlePage },
  { path: "/vidoe-analytics", exact: true, name: "Vidoe Analytics", layout: MemberDashboard, component: VidoeAnalytics },
  { path: "/audiance", exact: true, name: "Audiance", layout: MemberDashboard, component: Audiance },
  { path: "/identified-objects", exact: true, name: "Identified Objects", layout: MemberDashboard, component: IdentifiedObjects },
]

export default routes;