import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../Models.css'
import card1 from '../../../../../assets/images/card1.png'
import { HiOutlineClipboardDocument } from "react-icons/hi2"
const Visibility = () => {
    return(
        <>
            <div className='modalPage'>
            
            <div className=" themeModal" >
            <div className='seletFile modalBox'>
                <Row>
                    <Col md={8} className='mb-md-0 mb-3'> 
                        <h4 className='modal-H4'>Visibility</h4>
                        <p className='opacity-40'>Choose when to publish and who can see your video</p>
                        <div className='visibilityList'>
                            <div className='visibilityBox'>
                                <ul>
                                    <li>
                                        <div>
                                            <h5 className='h5Title'>Save or publish</h5>
                                            <p className='opacity-40'>Make your video public, unlisted or private.</p>
                                            <ul className='outer-UL'>
                                                <li>
                                                    <div>
                                                        <h5 className='h5Title'>Private</h5>
                                                        <p className='opacity-40 mb-0'>Only you can watch your video</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h5 className='h5Title'>Unlisted</h5>
                                                        <p className='opacity-40 mb-0'>Anyone with the video link can watch your video</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h5 className='h5Title'>Public</h5>
                                                        <p className='opacity-40 mb-0'>Everyone can watch your video</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </li>
                                </ul>
                            </div>
                            <div className='visibilityBox'>
                                <ul>
                                    <li>
                                        <div>
                                            <h5 className='h5Title'>Schedule</h5>
                                            <p className='opacity-40'>Everyone can watch your video</p>
                                        </div>
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </Col>
                    <Col md={4}>
                        <div className='videoLinkBox'>
                            <div className='videoLinkImg'>
                                <figure className='mb-0'><img className='img-fluid' src={card1} alt='Video'></img></figure>
                            </div>
                            <div className='vidoDetails'>
                                <h5 className='ellipse'>Video 20221205071229 Soldier Major Tank</h5>
                                <p className='mb-0'>Video Link:</p>
                                <div className='clipboardDiv'>
                                <Link to='/' className='videoLink ellipse'>https://www.biiview.com/Video2022</Link>
                                <span> <HiOutlineClipboardDocument/> </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>  
                </div>
            </div>
        </div>
        </>
    )
}
export default Visibility