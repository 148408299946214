import React from 'react';
import './DashboardHome.css';
import SiteCards from '../Home/Explore/SiteCards';



const DashboardHome = () => {
    return (
        <>
            <SiteCards />
        </>
    )
}
export default DashboardHome