import React from 'react'
// import { useState } from 'react'
import '../Forms.css'
import {Container, Form } from 'react-bootstrap'
import formLogo from '../../../../assets/images/formLogo.png'
import {useNavigate, Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { FaCalendarDay} from "react-icons/fa";

const Member = () => {

    function onChange(value) {
        console.log("Captcha value:", value);
      }
      const navigate = useNavigate();
      const navigateToPackage = () => {
          navigate('/package');
        };
        const navigateToLogin = () => {
            navigate('/login');
          };
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                            <div className='wrapForm'>
                                <Form className='formStyle'>
                                    <div className='formHeader'>
                                        <h2>Become Member</h2>
                                        <div className='d-flex align-items-end justify-content-center'>
                                        <p className='mb-0'>ALREADY MEMBER?</p><Link to='/member-login' onClick={navigateToLogin}  className='link ms-2 themeLink'>LOGIN</Link></div>
                                    </div>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Full Name</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Name"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email Address"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Password</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Your Password"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Verify Password</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Re-Enter Password"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <div className='inputDiv'>
                                            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Select DOB"></input>
                                            <FaCalendarDay/>
                                        </div>        
                                    </Form.Group>
                                    <div className='theme-capcha'>
                                        <ReCAPTCHA
                                            sitekey="Your client site key"
                                            onChange={onChange}
                                        />
                                          
                                        <button className='orange-btn text-uppercase' onClick={navigateToPackage}>CONTINUE</button>
                                    </div>
                                </Form>
                            </div>
                        </Container>
                    </div>
            </section>
        </>
    )
}
export default Member