import React from 'react'
import './siteTabs.css'
import { Row, Col} from 'react-bootstrap'
import ReactECharts from 'echarts-for-react'

const AnalyticsContent = () => {
    const colors = ['#5470C6', '#FD6F21'];
    const options = {
        color: colors,
        tooltip: {
            trigger: 'none',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {},
        grid: {
            top: 70,
            bottom: 50
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[1]
                    }
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return (
                                'Precipitation  ' +
                                params.value +
                                (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                            );
                        }
                    }
                },
                // prettier-ignore
                data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
            },
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[0]
                    }
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return (
                                'Precipitation  ' +
                                params.value +
                                (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                            );
                        }
                    }
                },
                // prettier-ignore
                data: ['2015-1', '2015-2', '2015-3', '2015-4', '2015-5', '2015-6', '2015-7', '2015-8', '2015-9', '2015-10', '2015-11', '2015-12']
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '',
                type: 'line',
                xAxisIndex: 1,
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: [
                    2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                ]
            },
            {
                name: '',
                type: 'line',
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: [
                    3.9, 5.9, 11.1, 18.7, 48.3, 69.2, 231.6, 46.6, 55.4, 18.4, 10.3, 0.7
                ]
            }
        ]
    };
    return(
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                <div className='analyticGraph'>
                    <div className='analyticsBar'>
                        <div className='analyticsBarBox'>
                            <span>Watch Time (Hours)</span>
                            <h4>32412</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                    </div>
                    <div className='d-flex flex-md-row flex-column justify-content-between align-item-center'>
                        <div className='graphItems pt-lg-4 px-lg-5 pt-3 px-4 pb-0'>
                            <ul>
                                <li><div className='orange'><p>Returning Viewers</p></div></li>
                                <li><div className='blue'><p>New Viewers</p></div></li>
                            </ul>
                        </div>
                    </div>

                    <ReactECharts option={options} />
                   
                </div>
                <Row className='tabBox'>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='audiance'>
                                        <h4>Audience retention</h4>
                                        <p className='md-P mb-0'>Since uploaded (lifetime)</p>
                                    </div>
                                    <p className='md-P text-center'>No audience retention data available for this video yet. Processing can take up to two days.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Likes (vs. dislikes)</h4>
                                        <p className='md-P mb-0'>Last 28 days</p>
                                    </div>
                                    <ul className='boxUl'>
                                        <li>
                                            <div className='analyticPer'>
                                                <p className='md-P'>1 SOLDIER MAJOR TANK</p>
                                                <div className='d-flex align-items-center justify-content-between dotDiv'><span className='orangeDot'></span><p className='md-P ms-2'>12.5%</p></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='analyticPer'>
                                                <p className='md-P'>Channel average</p>
                                                <div className='d-flex align-items-center justify-content-between dotDiv'><span className='greyDot'></span><p className='md-P ms-2'>14%</p></div>
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                        <Col md={6}>
                        <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>End screen element click rate</h4>
                                        <p className='md-P mb-0'>Last 28 days</p>
                                    </div>
                                    <ul className='boxUl'>
                                        <li>
                                            <div className='analyticPer'>
                                                <p className='md-P'>1 SOLDIER MAJOR TANK</p>
                                                <div className='d-flex align-items-center justify-content-between dotDiv'><span className='orangeDot'></span><p className='md-P ms-2'>12.5%</p></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='analyticPer'>
                                                <p className='md-P'>Channel average</p>
                                                <div className='d-flex align-items-center justify-content-between dotDiv'><span className='greyDot'></span><p className='md-P ms-2'>14%</p></div>
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                        <Col md={6}>
                        <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top Remixed</h4>
                                        <p className='md-P mb-0'>Shorts created using parts of this video · Last 28 days</p>
                                    </div>
                                    <p className='md-P'>Nothing to show for these dates</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default AnalyticsContent