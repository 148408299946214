import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../Models.css'
import card1 from '../../../../../assets/images/card1.png'
import { HiOutlineClipboardDocument } from "react-icons/hi2"
const VideoEle = () => {
    return(
        <>
            <div className='modalPage'>
                <div className=" themeModal" >
                    <div className='seletFile modalBox'>
                        <Row>
                            <Col md={8} className='mb-md-0 mb-3'> 
                                <h4 className='modal-H4'>Video Elements</h4>
                                <p className='opacity-40'>Use cards and an end screen to show viewers related video, websites, and cake to actions.</p>
                                <div className='addGroup'>
                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>Add Subtitles</h5>
                                            <p className='opacity-40 mb-0'>Reach a broader audience by adding subtitles to your video</p>
                                        </div>
                                        <button class="link orangeLink text-uppercase p-0">add</button>
                                    </div>
                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>Add an end screen</h5>
                                            <p className='opacity-40 mb-0'>Promote related content at the end of your video</p>
                                        </div>
                                        <button class="link orangeLink text-uppercase p-0">add</button>
                                    </div>
                                    <div className='addGroupList'>
                                        <div className='addBlock'>
                                            <h5 className='h5Title'>Add cards</h5>
                                            <p className='opacity-40 mb-0'>Promote related content during your video</p>
                                        </div>
                                        <button class="link orangeLink text-uppercase p-0">add</button>
                                    </div>
                                </div>
                                
                            </Col>
                            <Col md={4}>
                                <div className='videoLinkBox'>
                                    <div className='videoLinkImg'>
                                        <figure className='mb-0'><img className='img-fluid' src={card1} alt='Video'></img></figure>
                                    </div>
                                    <div className='vidoDetails'>
                                        <h5 className='ellipse'>Video 20221205071229 Soldier Major Tank</h5>
                                        <p className='mb-0'>Video Link:</p>
                                        <div className='clipboardDiv'>
                                        <Link to='/' className='videoLink ellipse'>https://www.biiview.com/Video2022</Link>
                                        <span> <HiOutlineClipboardDocument/> </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>  
                    </div>
                </div>
            </div>
        </>
    )
}
export default VideoEle