import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { FaVoicemail, FaPhone, FaLocationArrow, FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';

const GetInTouch = () => {
    return (
        <>
            <section className='getintouch-sec pb-0'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1>Get In Touch</h1>
                            <p>Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique nunc ut volutpat lectus.</p>
                            <div className='details'>
                                <a className='detail' href="mailto:info@biiview.com"><span><FaVoicemail /></span> info@biiview.com</a>
                                <a className='detail' href="tel:9366751343"><span><FaPhone /></span> (+1) 936-675-1343</a>
                                <a className='detail' href="/"><span><FaLocationArrow /></span> 2514 Steve Flanders SqStreet, NY 75901</a>
                            </div>
                            <div className='socialIcon'>
                                <ul>
                                    <li><Link><FaFacebookF /></Link></li>
                                    <li><Link><FaTwitter /></Link></li>
                                    <li><Link><FaInstagram /></Link></li>
                                    <li><Link><FaPinterest /></Link></li>
                                    <li><Link><FaYoutube /></Link></li>
                                </ul>
                            </div>
                            <div className='contactus-map'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.2542211644!2d-74.14482906318582!3d40.69763074431626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1671797500024!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='help-sec'>
                                <div className='form-wrapper'>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="number" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Your Message</Form.Label>
                                            <textarea className='w-100' rows={3}></textarea>
                                        </Form.Group>
                                        <div className='submit-btn text-end'>
                                            <Button variant="">Submit</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GetInTouch