import React from 'react'
import './About.css'
import Join from './Join'
import Overview from './Overview'
import Team from './Team'
import Together from './Together'
import Help from './Help'

const Aboutus = () => {
    return (
        <>
            <Overview />
            <Together />
            <Team />
            <Join />
            <Help />
        </>
    )
}

export default Aboutus