import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FaChevronRight } from "react-icons/fa";
import './Blogs.css'

const Index = () => {
    return (
        <>
            <section className='blogs-sec'>
                <Container className='customBox'>
                    <div className='blogs-banner'>
                        <div className='blog-img'>
                            <img src='./images/blog.png' className='img-fluid h-100 w-100' alt='' />
                        </div>
                        <div className='blogs-banner-content'>
                            <h1>Ut perspiciatis unde omnis istenatus error sit</h1>
                            <p className='date'>NOVEMBER 19, 2022</p>
                        </div>
                    </div>
                    <div className='trending-blogs'>
                        <Row>
                            <Col lg={8}>
                                <div className='trending-blogs-wrapper'>
                                    <div className='heading-links d-flex justify-content-between'>
                                        <h3 className='theme mb-0'>TRENDING</h3>
                                        <ul className='trending-links d-flex align-items-end mb-0'>
                                            <li><a href='/' className='active'>All</a></li>
                                            <li><a href='/'>Politics</a></li>
                                            <li><a href='/'>Business</a></li>
                                            <li><a href='/'>Technology</a></li>
                                            <li><a href='/'>Sports</a></li>
                                            <li><a href='/'>Travel</a></li>
                                        </ul>
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                        <Link to='/blog-details'>
                                            <div className='trending-wrapper'>
                                                <div className='img'>
                                                    <img src='./images/treding-img.png' alt='' className='img-fluid w-100 h-100' />
                                                </div>
                                                <div className='content'>
                                                    <span className='liftstyle-tag'>Lifestyle</span>
                                                    <h4 className='mb-2'>Sed ut perspiciatis unde omnis istenatus error sit voluptatem accusantium</h4>
                                                    <p className='date mb-0'>NOVEMBER 19, 2022</p>
                                                </div>
                                            </div>
                                        </Link>
                                        </Col>
                                        <Col md={6}>
                                            <Link to='/blog-details'>
                                                <div className='trending-wrapper'>
                                                    <div className='img'>
                                                        <img src='./images/treding-img.png' alt='' className='img-fluid w-100 h-100' />
                                                    </div>
                                                    <div className='content'>
                                                        <span className='liftstyle-tag'>Lifestyle</span>
                                                        <h4 className='mb-2'>Sed ut perspiciatis unde omnis istenatus error sit voluptatem accusantium</h4>
                                                        <p className='date mb-0'>NOVEMBER 19, 2022</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='related-posts related-posts3'>
                                    <p className='heading-sec'>Popular</p>
                                    <div className='related-posts-wrapper'>
                                        <div className='related-post d-flex align-items-center'>
                                            <div className='img'>
                                                <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                            </div>
                                            <div className='content'>
                                                <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                                <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                            </div>
                                        </div>
                                        <div className='related-post d-flex align-items-center'>
                                            <div className='img'>
                                                <img src='./images/related-post2.png' alt='' className='img-fluid h-100' />
                                            </div>
                                            <div className='content'>
                                                <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                                <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                            </div>
                                        </div>
                                        <div className='related-post d-flex align-items-center'>
                                            <div className='img'>
                                                <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                            </div>
                                            <div className='content'>
                                                <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                                <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                            </div>
                                        </div>
                                        <div className='related-post d-flex align-items-center'>
                                            <div className='img'>
                                                <img src='./images/related-post2.png' alt='' className='img-fluid h-100' />
                                            </div>
                                            <div className='content'>
                                                <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                                <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                            </div>
                                        </div>
                                        <div className='related-post d-flex align-items-center'>
                                            <div className='img'>
                                                <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                            </div>
                                            <div className='content'>
                                                <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                                <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='trending-news'>
                        <Row>
                            <Col md={4} sm={6}>
                                <div className='trending-news-wrapper'>
                                    <h4>International</h4>
                                    <div className='img'>
                                        <img src='./images/trending-news.png' className='img-fluid w-100 h-100' alt='' />
                                    </div>
                                    <p className='date mb-2'>NOVEMBER 19, 2022</p>
                                    <p className='heading'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                    <p className='description'>There are many variations of passages of Lorem Ipsum available the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                    <div className='read-more-btn'>
                                        <Button variant="">READ MORE</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <div className='trending-news-wrapper'>
                                    <h4>Business</h4>
                                    <div className='img'>
                                        <img src='./images/trending-news2.png' className='img-fluid w-100 h-100' alt='' />
                                    </div>
                                    <p className='date mb-2'>NOVEMBER 19, 2022</p>
                                    <p className='heading'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                    <p className='description'>There are many variations of passages of Lorem Ipsum available the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                    <div className='read-more-btn'>
                                        <Button variant="">READ MORE</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <div className='trending-news-wrapper'>
                                    <h4>Technologies</h4>
                                    <div className='img'>
                                        <img src='./images/trending-news2.png' className='img-fluid w-100 h-100' alt='' />
                                    </div>
                                    <p className='date mb-2'>NOVEMBER 19, 2022</p>
                                    <p className='heading'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                    <p className='description'>There are many variations of passages of Lorem Ipsum available the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                    <div className='read-more-btn'>
                                        <Button variant="">READ MORE</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='related-posts related-posts2'>
                        <div className='related-posts-wrapper'>
                            <Row>
                                <Col md={6} lg={4}>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={4}>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post2.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={4}>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={4}>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={4}>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={4}>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='perspiciatis-images'>
                        <Row>
                            <Col md={6}>
                                <div className='perspiciatis-wrapper'>
                                    <div className='img'>
                                        <img src='./images/perspiciatis.png' className='img-fluid w-100 h-100' alt='' />
                                    </div>
                                    <div className='content'>
                                        <span className='date'>NOVEMBER 19, 2022</span>
                                        <h3 className='heading mb-0 mt-2'>Perspiciatis unde omnis iste natus</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='perspiciatis-wrapper'>
                                    <div className='img'>
                                        <img src='./images/perspiciatis2.png' className='img-fluid w-100 h-100' alt='' />
                                    </div>
                                    <div className='content'>
                                        <span className='date'>NOVEMBER 19, 2022</span>
                                        <h3 className='heading mb-0 mt-2'>Perspiciatis unde omnis iste natus</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='information-technologies'>
                        <Row>
                            <Col lg={8}>
                                <h3 className='heading'>Information Technologies</h3>
                                <div className='information-technologies-wrapper'>
                                    <div className='single-img'>
                                        <div className='img'>
                                            <img src='./images/1.png' className='img-fluid h-100 w-100' alt='' />
                                        </div>
                                        <div className='content'>
                                            <h2 className='heading'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</h2>
                                            <span className='date'>NOVEMBER 19, 2022</span>
                                        </div>
                                    </div>
                                    <div className='related-blogs related-blogs2'>
                                        <Row>
                                            <Col sm={6}>
                                                <div className='related-blogs-wrapper'>
                                                    <div className='img'>
                                                        <img src='./images/2.png' alt='' className='img-fluid mb-0 h-100 w-100' />
                                                    </div>
                                                    <div className='content'>
                                                        <p className='blog-date mb-2'>NOVEMBER 19, 2022</p>
                                                        <p className='blog-heading mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className='related-blogs-wrapper'>
                                                    <div className='img'>
                                                        <img src='./images/3.png' alt='' className='img-fluid mb-0 h-100 w-100' />
                                                    </div>
                                                    <div className='content'>
                                                        <p className='blog-date mb-2'>NOVEMBER 19, 2022</p>
                                                        <p className='blog-heading mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className='catagories'>
                                <h3 className='heading'>Categories</h3>
                                    <div className='categories-wrapper'>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Development</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Artificial Intelligence</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Machine Learning</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Deep Learinig</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Augmenting Reality</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Technology</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Design</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Back-End Development</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Front-End Development</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Virtual Reality</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Un-Real</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Unity Development</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Metaverse</a><span className='category-count'>12</span>
                                        </div>
                                        <div className='category d-flex justify-content-between align-items-center'>
                                            <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Game Development</a><span className='category-count'>12</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Index