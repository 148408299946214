import React from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FaShare, FaChevronLeft, FaChevronRight, FaInstagram, FaFacebook, FaTwitter, FaPinterest } from "react-icons/fa";
import './BlogDetails.css'

const Index = () => {
    return (
        <>
            <section className='blog-details-sec pb-0'>
                <Container className='customBox'>
                    <div className='breadcrum'>
                        <p><span className='prev'>Blogs /</span> Sed ut perspiciatis unde omnis istenatus error sit voluptatem</p>
                    </div>
                    <div className='heading'>
                        <h1>Sed ut perspiciatis unde omnis istenatus error sit voluptatem</h1>
                    </div>
                    <Row>
                        <Col lg={8}>
                            
                    <div className='post-details d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='auther-date d-flex flex-wrap'>
                            <p className='author-name mb-2'>Posted by John Doe</p>
                            <p className='mb-2'>July 29, 2019</p>
                        </div>
                        <div className='share-btn'>
                            <Button variant=""><FaShare className='me-2' />Share</Button>
                        </div>
                    </div>
                            <div className='post-img'>
                                <img src='./images/post.png' className='img-fluid h-100 w-100' alt='' />
                            </div>
                            <div className='post-details'>
                                <h4>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</h4>
                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</p>
                                <div className='img2'>
                                    <img src='./images/post2.png' alt='' className='img-fluid h-100' />
                                </div>
                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</p>
                                <h5>The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains</h5>
                                <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many websites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like</p>
                                <div className='blog-names'>
                                    <div className='wrapper1'>
                                        <div className='d-flex align-items-center'>
                                            <FaChevronLeft />
                                            <p className='mb-0'>Quis autem vel eum iure qui involuptate velit</p>
                                        </div>
                                    </div>
                                    <div className='wrapper2'>
                                        <div className='d-flex align-items-center'>
                                            <p className='mb-0'>Quis autem vel eum iure qui involuptate velit</p>
                                            <FaChevronRight />
                                        </div>
                                    </div>
                                </div>
                                <div className='related-blogs'>
                                    <h4>Related Blogs</h4>
                                    <Row>
                                        <Col md={4} sm={6}>
                                            <div className='related-blogs-wrapper'>
                                                <div className='img'>
                                                    <img src='./images/related-blogs1.png' alt='' className='img-fluid mb-0 h-100 w-100' />
                                                </div>
                                                <div className='content'>
                                                    <p className='blog-date mb-2'>NOVEMBER 19, 2022</p>
                                                    <p className='blog-heading mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6}>
                                            <div className='related-blogs-wrapper'>
                                                <div className='img'>
                                                    <img src='./images/related-blogs2.png' alt='' className='img-fluid mb-0 h-100 w-100' />
                                                </div>
                                                <div className='content'>
                                                    <p className='blog-date mb-2'>NOVEMBER 19, 2022</p>
                                                    <p className='blog-heading mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={6}>
                                            <div className='related-blogs-wrapper'>
                                                <div className='img'>
                                                    <img src='./images/related-blogs3.png' alt='' className='img-fluid mb-0 h-100 w-100' />
                                                </div>
                                                <div className='content'>
                                                    <p className='blog-date mb-2'>NOVEMBER 19, 2022</p>
                                                    <p className='blog-heading mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='related-posts'>
                                <p className='heading-sec'>Related Post</p>
                                <div className='related-posts-wrapper'>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post2.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post2.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                    <div className='related-post d-flex align-items-center'>
                                        <div className='img'>
                                            <img src='./images/related-post1.png' alt='' className='img-fluid h-100' />
                                        </div>
                                        <div className='content'>
                                            <p className='related-post-date mb-2'>NOVEMBER 19, 2022</p>
                                            <p className='related-post-description mb-0'>Quis autem vel eum iure qui in ea voluptate velit esse quam nihil</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='popular-tags'>
                                <p className='heading-sec'>Popular Tags</p>
                                <div className='popular-tags-wrapper d-flex flex-wrap'>
                                    <a className='tag' href="#">Technology</a>
                                    <a className='tag' href="#">UI/UX Design</a>
                                    <a className='tag' href="#">UI/UX Development</a>
                                    <a className='tag' href="#">Development</a>
                                    <a className='tag' href="#">Design</a>
                                    <a className='tag' href="#">Artificial Intelligence</a>
                                    <a className='tag' href="#">Machine Learning</a>
                                    <a className='tag' href="#">Deep Learning</a>
                                    <a className='tag' href="#">Virtual Reality</a>
                                    <a className='tag' href="#">Augmenting Reality</a>
                                    <a className='tag' href="#">Un-Real</a>
                                    <a className='tag' href="#">Metaverse</a>
                                    <a className='tag' href="#">Front-End Development</a>
                                    <a className='tag' href="#">Back-End Development</a>
                                    <a className='tag' href="#">Unity Development</a>
                                    <a className='tag' href="#">Game Development</a>
                                </div>
                            </div>
                            <div className='catagories'>
                                <p className='heading-sec'>Categories</p>
                                <div className='categories-wrapper'>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Development</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Artificial Intelligence</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Machine Learning</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Deep Learinig</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Augmenting Reality</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Technology</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Design</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Back-End Development</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Front-End Development</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Virtual Reality</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Un-Real</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Unity Development</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Metaverse</a><span className='category-count'>12</span>
                                    </div>
                                    <div className='category d-flex justify-content-between align-items-center'>
                                        <a className='category-name d-flex align-items-center' href='#'><FaChevronRight /> Game Development</a><span className='category-count'>12</span>
                                    </div>
                                </div>
                            </div>
                            <div className='social-media'>
                                <p className='heading-sec'>Social Media</p>
                                <Row>
                                    <Col sm={6}>
                                        <div className='social instagram d-flex align-items-center'>
                                            <div className='icon'>
                                                <FaInstagram />
                                            </div>
                                            <div className='details d-flex flex-column'>
                                                <span>52k</span>
                                                <span>Followers</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='social facebook d-flex align-items-center'>
                                            <div className='icon'>
                                                <FaFacebook />
                                            </div>
                                            <div className='details d-flex flex-column'>
                                                <span>52k</span>
                                                <span>Followers</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='social twitter d-flex align-items-center'>
                                            <div className='icon'>
                                                <FaTwitter />
                                            </div>
                                            <div className='details d-flex flex-column'>
                                                <span>52k</span>
                                                <span>Followers</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className='social pinterest d-flex align-items-center'>
                                            <div className='icon'>
                                                <FaPinterest />
                                            </div>
                                            <div className='details d-flex flex-column'>
                                                <span>52k</span>
                                                <span>Followers</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Index