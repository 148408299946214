import React from 'react'
import '../Models.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
const EditDes = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    return(
        <>
            <div className='modalPage'>
                <div className="modal show" >
                    <Modal show={show} onHide={handleClose} className="narrowModel">
                        <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>Edit Title & Description</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='py-lg-4 py-3'>
                            <div className='fileGroup'>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>Title (required)</p>
                                    <div className='fileBlock'>
                                        <p>Video 20221205071229 Soldier Major Tank</p>
                                    </div>
                                </div>
                                <div className='fileGroupList'>
                                    <p className='fileLabel'>Description</p>
                                    <Form.Group className='w-100' as="textarea" rows={3} placeholder='Tell us something about your video….'>
                                    </Form.Group>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='pb-0'>
                            <div className='d-flex align-items-center'>
                                <button class="grey-btn text-uppercase me-lg-3 me-2 mt-0">close</button>
                                <Link to='/content' class="m-0 p-0"><button class="orange-btn text-uppercase mx-auto mt-0" onClick={handleClose}>save</button></Link>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default EditDes