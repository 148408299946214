import React from 'react'
import { useState } from 'react'
import '../Models.css'
import { TiArrowSortedDown } from "react-icons/ti"
const General = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    return(
        <>
            <div className='modalPage h-100' show={show} onHide={handleClose}>
                <div className="themeModal settingFlex" >
                <div className='general'>
                    <h4>Default Units</h4>
                    <div className='modalSelect'>
                        <select className="form-select" aria-label="Default select example">
                            <option selected>USD - US Dollar</option>
                            <option value="1">USD - US Dollar1</option>
                            <option value="2">USD - US Dollar2</option>
                            <option value="3">USD - US Dollar2</option>
                        </select>
                        <span><TiArrowSortedDown/></span>
                    </div>
                </div>

                </div>
            </div>
        </>
    )
}
export default General