import React from 'react'
import './Models.css'
import {AiOutlineCheck} from "react-icons/ai"
const TabButton = () => {

    return(
        <>
            <div className='tabButton'>
                <div className='d-flex flex-column'>
                    <div><p className='tabTitle'>Details</p></div>
                    <div className='circleBef'><div className='tickCircle'><AiOutlineCheck/></div></div>
                </div>
            </div>

        </>
    )
}
export default TabButton