import React from 'react'
import { useState } from 'react'
import { Row, Col, Nav, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Models.css'
import Modal from 'react-bootstrap/Modal'
import General from './ModelTabsComp/General'
import Channel from './ModelTabsComp/Channel'
import UploadDefault from './ModelTabsComp/UploadDefault'
import Permissions from './ModelTabsComp/Permissions'
import Community from './ModelTabsComp/Community';
const SettingsModel = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    return(
        <>
            <div className='modalPage'>
                <div className="modal themeModal" >
                    <Modal className='settingModel fixedModal' show={show} onHide={handleClose}>
                    <div className='fixedRel'>
                        <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Settings</Modal.Title>
                        </Modal.Header>
                        <div className='modelTabs'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                                <Row>
                                    <Col sm={3} className='pe-0'>
                                    <Nav variant="pills" className="flex-column sideTab">
                                        <Nav.Item>
                                            <Nav.Link eventKey="1">General</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="2">Channel</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="3">Upload Default</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="4">Permissions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="5">Community</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    </Col>
                                    <Col sm={9} className='px-0'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="1"><General/></Tab.Pane>
                                        <Tab.Pane eventKey="2"><Channel/></Tab.Pane>
                                        <Tab.Pane eventKey="3"><UploadDefault/></Tab.Pane>
                                        <Tab.Pane eventKey="4"><Permissions/></Tab.Pane>
                                        <Tab.Pane eventKey="5"><Community/></Tab.Pane>
                                    </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        <Modal.Footer>
                            <div className='d-flex align-items-center '>
                                <Link onClick={handleClose} to='/member-dashboard' class="theme-btn grey-btn text-uppercase me-lg-3 me-2 mt-0">close</Link>
                                <Link onClick={handleClose} to='/member-dashboard' class="theme-btn orange-btn text-uppercase mx-auto mt-0">Save</Link>
                            </div>
                        </Modal.Footer>
                    </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default SettingsModel