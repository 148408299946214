import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'


const Overview = () => {
    return (
        <>
            <section className='overview-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1 className='mb-0'>This is an <span class="theme">Overview Page</span> to let you know more about us that <span class="theme">What We Are Doing</span>.</h1>
                        </Col>
                        <Col lg={6} className="d-flex align-items-end">
                            <h3 className='mb-0'>Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique nunc ut volutpat lectus.</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className='people-working'>
                <img className='aboutImg img-fluid' src='/images/people-working.png' alt='about-biiview' />
            </div>
        </>
    )
}

export default Overview