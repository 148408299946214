import React from 'react'
import { Link } from 'react-router-dom'
import { Table} from 'react-bootstrap'
const VideoSubtitlePage = () => {
    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader d-flex justify-content-between align-item-center'>
                    <h2>Video Subtitles</h2>
                </div>
                <div className='content px40'>
                    <div className='themeTable'>
                        <Table resposinve>
                            <thead>
                                <tr>
                                <th><p>Language</p></th>
                                <th><p>Modified On</p></th>
                                <th><p>Title & Description</p></th>
                                <th><p>Subtitles</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>English United States (Video Language)</p></td>
                                    <td><p>Dec 8, 2022</p></td>
                                    <td><p>Published</p></td>
                                    <td><p>English</p></td>
                                </tr>
                                <tr>
                                    <td><p>English United States (Video Language)</p></td>
                                    <td><p>Dec 8, 2022</p></td>
                                    <td><p>Published</p></td>
                                    <td><p>English</p></td>
                                </tr>
                                <tr>
                                    <td><p>-</p></td>
                                    <td><p>-</p></td>
                                    <td><p>-</p></td>
                                    <td><button class="link orangeLink text-uppercase p-0">add</button></td>
                                </tr>

                            </tbody>
                        </Table>
                    </div>
                    <Link to='/subtitles' class="theme-btn orange-btn text-uppercase">add language</Link>
                </div>
            </div>
        </>
    )
}
export default VideoSubtitlePage