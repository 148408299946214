import React from 'react'
import { useState } from 'react'
import './DasboardPage.css'
import { Row, Col,Dropdown } from 'react-bootstrap'
import download from '../../../../assets/images/download.png'
import { IoCaretDownSharp } from "react-icons/io5"
import UploadVideo from '../Models/UploadVideo'
import ReactECharts from 'echarts-for-react'

const DasboardPage = () => {
    const [upload, setUpload] = useState(false)
    const [menu, setMenu] =useState(false)

    const colors = ['#5470C6', '#FD6F21', '#fff'];
    const options = {
        color: colors,
        tooltip: {
            trigger: 'none',
            axisPointer: {
                color: colors[3],
                type: 'cross'
            }
        },
        legend: {},
        grid: {
            top: 70,
            bottom: 50
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[1]
                    }
                },
                // axisPointer: {
                //     label: {
                //         formatter: function (params) {
                //             return (
                //                 'Precipitation  ' +
                //                 params.value +
                //                 (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                //             );
                //         }
                //     }
                // },
                data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
            },
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        // color: colors[0]
                    }
                },
                // axisPointer: {
                //     label: {
                //         color: colors[1],
                //         formatter: function (params) {
                //             return (
                //                 'Precipitation  ' +
                //                 params.value +
                //                 (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                //             );
                //         }
                //     }
                // },
                // prettier-ignore
                data: ['2015-1', '2015-2', '2015-3', '2015-4', '2015-5', '2015-6', '2015-7', '2015-8', '2015-9', '2015-10', '2015-11', '2015-12']
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '',
                color: colors[1],
                type: 'line',
                xAxisIndex: 1,
                smooth: true,
                emphasis: {
                    focus: 'series',
                    color: colors[1]
                },
                data: [
                    2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                ]
            },
            {
                name: '',
                type: 'line',
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: [
                    3.9, 5.9, 11.1, 18.7, 48.3, 69.2, 231.6, 46.6, 55.4, 18.4, 10.3, 0.7
                ]
            }
        ]
    };
     
    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader'>
                    <h2>Dashboard</h2>
                </div>
                <div className='px40'>
                    <Row className='boxMb80'>
                        <Col lg={6} className='mb-lg-0 mb-3'>
                            <div className='box'>
                                <h2>Upload Video</h2>
                                <div className='upload'>
                                    <img className='img-fluid' src={download} alt='download'></img>
                                    <h4>Upload a video to get started</h4>
                                    <p>Want to see metrics on your recent video? <br></br> Upload & publish a video to get started</p>
                                    <button className='orange-btn text-uppercase mx-auto' onClick={() => setUpload(!upload)}>UPLOAD VIDEO</button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className='mb-lg-0 mb-3'>
                        <div className='box analyticBox'>
                                <h2>Analytics</h2>
                                <div className='analytics'>
                                    <div>
                                        <h4>Summary</h4>
                                        <div className='searchByDays'>
                                            <div><p className='md-16 me-5'>Last 30 days</p></div>
                                            <Dropdown className='iconDD'>
                                                <Dropdown.Toggle className='iconBtn iconDD-Btn' id="dropdown-basic" onChange={() => setMenu}>
                                                    <span onClick={() => setMenu(!menu)} className={menu ? 'menuRotate' : 'menuOriginal' }><IoCaretDownSharp/></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                    <span className='me-2'></span>24 Hours</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2"><span className='me-2'></span>7 Days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><span className='me-2'></span>15 Days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><span className='me-2'></span>30 Days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><span className='me-2'></span>Custom</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className='analyticSummary'>
                                        <ul>
                                            <li>
                                                <div>
                                                    <p>Views Total</p>
                                                </div>
                                                <div>
                                                    <p>32,431</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <p>Recognised Objects</p>
                                                </div>
                                                <div>
                                                    <p>2341 </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <p>Labels</p>
                                                </div>
                                                <div>
                                                    <p>231</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h4>Top videos</h4>
                                        <p className='dashboard-p'>Last 30 days - 3412 Recognised Objects</p>
                                        <span className='count'>23</span>
                                    </div>
                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className='mb-lg-0 mb-3'>
                            <div className='box'>
                                <h4>Top Videos</h4>
                                <div className='wrapVideoInfo'>
                                    <ul>
                                        <li>
                                            <div className='videoInfoDiv'>
                                                <div className='videoImg'><img className='img-fluid' src={download} alt='download'></img></div>
                                                <div className='videoContent'>
                                                    <h4>Handle coming out over coming weeks</h4>
                                                    <p>213 Recognised Objects</p>
                                                    <p>12 Labels</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='videoInfoDiv'>
                                                <div className='videoImg'><img className='img-fluid' src={download} alt='download'></img></div>
                                                <div className='videoContent'>
                                                    <h4>Handle coming out over coming weeks</h4>
                                                    <p>213 Recognised Objects</p>
                                                    <p>12 Labels</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='videoInfoDiv'>
                                                <div className='videoImg'><img className='img-fluid' src={download} alt='download'></img></div>
                                                <div className='videoContent'>
                                                    <h4>Handle coming out over coming weeks</h4>
                                                    <p>213 Recognised Objects</p>
                                                    <p>12 Labels</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className='boxMb80'>
                        <Col lg={12}>
                            <div>
                            <h2>Analytical Graph</h2>
                            <div className='box h-auto'>
                                <div className='d-flex flex-md-row flex-column justify-content-between align-item-center'>
                                    <h2>Analytics</h2>
                                    <div className='graphItems'>
                                        <ul>
                                            <li><div className='yellow'><p>Views</p></div></li>
                                            <li><div className='blue'><p>Recognized Object</p></div></li>
                                            <li><div className='orange'><p>Labels</p></div></li>
                                        </ul>
                                    </div>
                                </div>
                                <ReactECharts option={options} />
                               
                            </div>
                            </div>
                        </Col>
                        
                    </Row>
                </div>
            </div>
            {upload && <UploadVideo />}
        </>
    )
}
export default DasboardPage