import React from 'react'
import '../Models.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
// import { TiArrowSortedDown } from "react-icons/ti"
// import Modal from 'react-bootstrap/Modal'
import BasicInfo from './BasicInfo'
import AdvanceSettings from './AdvanceSettings'
import FeatureEligibility from './FeatureEligibility'
const Channel = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex innerTabs" >
                    <Tabs>
                        <TabList>
                            <Tab>Basic Info</Tab>
                            <Tab>Advance Settings</Tab>
                            <Tab>Feature eligibility</Tab>
                        </TabList>
                        <TabPanel>
                            <BasicInfo/>
                        </TabPanel>
                        <TabPanel>
                            <AdvanceSettings/>
                        </TabPanel>
                        <TabPanel>
                            <FeatureEligibility/>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default Channel