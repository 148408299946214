import React from 'react'
import { useState } from 'react'
import './MemberDashboardSidebar.css'
import '../DasboardPage/DasboardPage.css'
import dashbordLogo from '../../../../assets/images/logo.png'
import user from '../../../../assets/images/user.png'
import dashboardIcon from '../../../../assets/images/dashboardIcon.svg'
import contentIcon from '../../../../assets/images/contentIcon.svg'
import analyticIcon from '../../../../assets/images/analyticIcon.svg'
import subtitleIcon from '../../../../assets/images/subtitleIcon.svg'
import settingIcon from '../../../../assets/images/settingIcon.svg'
import biiview from '../../../../assets/images/biiview.svg'
import { AiOutlineAlignCenter } from "react-icons/ai"
import { Link } from 'react-router-dom'
import { RiLogoutCircleRLine } from "react-icons/ri";
import SettingsModel from '../Models/SettingsModel'

const DashboardSidebar = ({ dashboardBar, setDashboardBar }) => {
    const [show, setShow] = useState(false)
    const [settingsModel, setSettingsModel] = useState(false)
    return (
        <>
            <div className='dashboardBody'>
                <div className='dashboard-Sidebar'>
                    <div className={dashboardBar ? 'dsahboardLogo logoTrue' : ' dsahboardLogo-change logoTrue'} 
                        onClick={() => setDashboardBar(!dashboardBar)}><AiOutlineAlignCenter></AiOutlineAlignCenter></div>
                    {
                        dashboardBar ?
                            <div className='dashboardFlex' onMouseOver={() => setShow(!show)}>
                                <div>
                                    <div className='dashboardLogo'>
                                        <Link to='/'> <img className='img-fluid' src={dashbordLogo} alt='BiiView'></img> </Link>
                                    </div>
                                    <div className='autoBar'>
                                        <div className='user'>
                                            <div className='userLink'>
                                                <div className='userBlock'>
                                                    <div className='userImg'><img className='img-fluid' src={user} alt='user'></img></div>
                                                    <h3>Elizabeth Fox</h3>
                                                    <p>245 Videos Uploaded</p>
                                                </div>
                                                <ul className='dashboard-ul'>
                                                    <li><Link to='/member-dashboard' className='active'> <img className='img-fluid me-3' src={dashboardIcon} alt='dashboard'></img> Dashboard</Link></li>
                                                    <li><Link to='/content' className=''> <img className='img-fluid me-3' src={contentIcon} alt='content'></img> Content</Link></li>
                                                    <li><Link to='/channel-analytics' className=''> <img className='img-fluid me-3' src={analyticIcon} alt='analysis'></img> Analytics</Link></li>
                                                    <li><Link to='/subtitles' className=''> <img className='img-fluid me-3' src={subtitleIcon} alt='subtitles'></img> Subtitles</Link></li>
                                                    <li><Link  onClick={() => setSettingsModel(!settingsModel)} className=''> <img className='img-fluid me-3' src={settingIcon} alt='settings'></img> Settings</Link></li>
                                                </ul>
                                            </div>
                                            <div><Link to='/member-login' class="theme-btn orange-btn text-uppercase">logout</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='dashboard-sidebar MobileSidebar'>
                                <div>
                                    <div className='dashboardLogo px-1'>
                                        <div><img className='img-fluid' src={biiview} alt='biiview'></img></div>
                                    </div>
                                    <div className='autoBar'>
                                        <div className='user'>
                                            <div className='userLink'>
                                                {/* <div className='userBlock'>
                                                    <div className='userImg'><img className='img-fluid' src={user} alt='user'></img></div>
                                                    <h3>Elizabeth Fox</h3>
                                                    <p>245 Videos Uploaded</p>
                                                </div> */}
                                                <ul className='dashboard-ul'>
                                                    <li><Link to='/member-dashboard' className='active'> <img className='img-fluid' src={dashboardIcon} alt='dashboard'></img></Link></li>
                                                    <li><Link to='/content' className=''> <img className='img-fluid' src={contentIcon} alt='content'></img></Link></li>
                                                    <li><Link to='/channel-analytics' className=''> <img className='img-fluid' src={analyticIcon} alt='analysis'></img></Link></li>
                                                    <li><Link to='/subtitles' className=''> <img className='img-fluid' src={subtitleIcon} alt='subtitles'></img></Link></li>
                                                    <li><Link onClick={() => setSettingsModel(!settingsModel)}> <img className='img-fluid' src={settingIcon} alt='settings'></img> </Link></li>
                                                    <li><Link  onClick={() => setSettingsModel(!settingsModel)} className=''> <img className='img-fluid me-3' src={settingIcon} alt='settings'></img></Link></li>
                                                </ul>
                                            </div>
                                            <div><Link to='/member-login' class="theme-btn orange-btn text-uppercase px-2 py-1 m-auto"><RiLogoutCircleRLine /></Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {settingsModel && <SettingsModel/>}
            </div>
        </>
    )
}
export default DashboardSidebar