import React from 'react'
import Details from './Details'
import './Tearms&Conditions.css'
import Terms from './Terms'

const Index = () => {
  return (
    <>
        <Terms />
        <Details />
    </>
  )
}

export default Index