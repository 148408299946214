import React from 'react'
import '../Models.css'
const Community = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                    <div className='general'>
                        <h4>Automated Filters</h4>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div class="innerTab-input-group">
                                    <input type="text" class="form-control" placeholder="Add moderator" aria-label="Username" />
                                </div>
                            </div>
                            <p className='opacity-40'>Paste the channel URL of a user to add as an approved user.</p>
                        </div>
                        <div>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div class="innerTab-input-group">
                                    <input type="text" class="form-control" placeholder="App approved user" aria-label="Username" />
                                </div>
                            </div>
                            <p className='opacity-40'>Paste the channel URL of a user to add as an approved user.</p>
                        </div>
                        </div>
                        <div>
                            <div className='general mb-0'>
                                <div className='mb-2'>
                                    <div class="innerTab-input-group">
                                        <input type="text" class="form-control" placeholder="Add hidden user" aria-label="Username" />
                                    </div>
                                </div>
                                <p className='opacity-40'>Paste the channel URL of a user to add as a hidden user.</p>
                            </div>
                        </div>
                    </div>
                    <div className='general'>
                        <h4>Live redirects</h4>
                        <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                        <div className='d-flex justify-content-start align-items-center mb-3'>
                            <div className="form-check checkTable me-2 px-0 modalCheck">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <h4 className='check-H4 mb-0'>Allow automatic chapters and key moments</h4>
                        </div>
                        <div className='d-flex justify-content-start align-items-center mb-3'>
                            <div className="form-check checkTable me-2 px-0 modalCheck">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <h4 className='check-H4 mb-0'>Allow automatic chapters and key moments</h4>
                        </div>
                        <p className='opacity-40'>Rhis option is disabled</p>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div class="innerTab-input-group">
                                    <input type="text" class="form-control" placeholder="Add user for live redirects" aria-label="Username" />
                                </div>
                            </div>
                            <p className='opacity-40'>Paste the channel URL of a user to add as an approved user.</p>
                        </div>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div class="innerTab-input-group">
                                    <input type="text" class="form-control" placeholder="App approved user" aria-label="Username" />
                                </div>
                            </div>
                            <p className='opacity-40'>Paste the channel URL of a user to add as an approved user.</p>
                        </div>
                        <div className='general mb-0'>
                            <div className='mb-2'>
                                <div class="innerTab-input-group">
                                    <input type="text" class="form-control" placeholder="Add Blocked words" aria-label="Username" />
                                </div>
                            </div>
                            <p className='opacity-40'>Enter comma seprated values</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center mb-3'>
                            <div className="form-check checkTable me-2 px-0 modalCheck">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <h4 className='check-H4 mb-0'>Block links</h4>
                        </div>
                        <p className='opacity-40'>Allows other creators to send their viewers to any of your live streams or Premieres. Your content can only be selected for a live redirect if it’s upcoming or in progress.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Community