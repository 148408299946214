import React from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom'
import './MainSidebar.css'
import memberImg from '../../../assets/images/memberImg.png'
import homeIcon from '../../../assets/images/homeIcon.svg'
import homeIcon2 from '../../../assets/images/homeIcon2.svg'
import userIcon from '../../../assets/images/userIcon.svg'
import homeIcon3 from '../../../assets/images/homeIcon3.svg'
import { SiAcclaim } from "react-icons/si"
import { RiLogoutCircleRLine } from "react-icons/ri"



const MainSidebar = ({sidebar, setSidebar}) => {
    
    const [show, setShow] = useState(false)
    return (
        <>
            <div className='mainSidebar'>
                <div className={sidebar ? 'sidebarLogo rotate' : 'sidebarLogo logo-change'}
                    onClick={() => setSidebar(!sidebar)}><SiAcclaim></SiAcclaim></div>
                {
                    sidebar ?
                        <div className='sidebarStyle sidebarFlex' onMouseOver={() => setShow(!show)}>
                            <div>
                                <h3>explore videos</h3>
                                <ul>
                                    <li><a href='/' className='active'><span><img className='me-3 img-fluid'
                                        src={homeIcon} alt='Home' />Home</span></a></li>
                                    <li><a href='/'><span><img className='me-3 img-fluid' src={homeIcon2}
                                        alt='Home' />go to label</span></a></li>
                                    <li><a href='/'><span><img className='me-3 img-fluid' src={userIcon}
                                        alt='Home' />User</span></a></li>
                                    <li><a href='/'><span><img className='me-3 img-fluid' src={homeIcon3}
                                        alt='Home' />Explore</span></a></li>
                                </ul>
                                <h3 className='text-center px-0'>Become a member</h3>
                                <div className='text-center memberImg'>
                                    <Link to='/back-package'><img className='img-fluid' src={memberImg} alt='Become-a-memeber' /></Link>
                                </div>
                            </div>
                            <div>
                                <Link to='/login' class="theme-btn orange-btn text-uppercase">logout</Link>
                            </div>
                        </div>
                        :
                        <div className='sidebarStyle sidebarFlex w2'>
                            <div >
                                {/* <h3>explore videos</h3> */}
                                <ul>
                                    <li><a href='/' className='active'><span><img className='me-3 img-fluid'
                                        src={homeIcon} alt='Home' /></span></a></li>
                                    <li><a href='/'><span><img className='me-3 img-fluid' src={homeIcon2}
                                        alt='Home' /></span></a></li>
                                    <li><a href='/'><span><img className='me-3 img-fluid' src={userIcon}
                                        alt='Home' /></span></a></li>
                                    <li><a href='/'><span><img className='me-3 img-fluid' src={homeIcon3}
                                        alt='Home' /></span></a></li>
                                       
                                </ul>
                                <div className='text-center memberImg mobileMemberImg'>
                                    <Link to='/back-package' className='d-block'><img className='img-fluid' src={memberImg} alt='Become-a-memeber' /></Link>
                                </div>
                            </div>
                            <div>
                                {
                                    sidebar ?
                                    <Link to='/login' class="theme-btn orange-btn text-uppercase">logout</Link>
                                    :
                                    <Link to='/login' class="theme-btn logout text-uppercase p-2 mx-auto"><RiLogoutCircleRLine /></Link>
                                }
                            </div>
                        </div>
                }
            </div>
        </>
    )
}
export default MainSidebar