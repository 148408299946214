import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Join = () => {
    return (
        <>
            <section className='join-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1>Join our <span className='theme'>Team</span></h1>
                        </Col>
                        <Col lg={6}>
                            <p>We believe it takes great people to make a good product. That’s why we hire not only the perfect professional fits, but people who embody our company values.</p>
                            <Link to={''} className='link outer-p joinBtn'>See one positions</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Join