import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Query = () => {
    return (
        <>
            <section className='query-sec'>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1 className='mb-0'>Do you still have any <span className='theme'>Query?</span> Send us <span className='theme'>Message</span> Our team will contact you.</h1>
                        </Col>
                        <Col lg={6} className="d-flex align-items-end">
                            <h3 className='mb-0'>Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique nunc ut volutpat lectus.</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Query