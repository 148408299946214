import React from 'react';
import './siteTabs.css';
import { Row, Col} from 'react-bootstrap'
import ReactECharts from 'echarts-for-react'

const Reach = () => {
    const colors = ['#FD6F21'];
    const options = {
        color: colors,
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            color: colors[1]
          }
        ]
      }
    return(
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                <div className='analyticGraph'>
                    <div className='analyticsBar'>
                        <div className='analyticsBarBox'>
                            <span>Watch Time (Hours)</span>
                            <h4>32412</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                    </div>
                    <ReactECharts option={options} />
                </div>
                <Row className='tabBox'>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>How views find this video</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Impressions and how they led to watch time</h4>
                                        <p className='md-P mb-0'>Data available Nov 18 – Dec 15, 2022 (28 days)</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough impressions data to show this report.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>External sites or apps</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                        <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>BiiView search terms</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                        <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Content suggesting this video</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default Reach