import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Together = () => {
    return (
        <>
            <section className='together-sec'>
                <div className='bar'></div>
                <Container className='customBox'>
                    <Row>
                        <Col lg={6}>
                            <h1 className='mb-0'>Together <span className='theme'>we are </span>Strong.</h1>
                        </Col>
                        <Col lg={6}>
                            <h3 className='mb-4'>Our crew is always getting bigger, but we all work toward one goal Integer at faucibus urna. Nullam condimentum Leo id elit sagittis auctor.</h3>
                            <p>Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. Proin eleifend eget quam ut efficitur. Mauris a accumsan mauris. Phasellus egestas et risus sit amet hendrerit. Nulla facilisi. Cras urna sem, vulputate sed condimentum a, posuere vel enim. Integer at faucibus urna. Nullam condimentum leo id elit sagittis auctor. Curabitur elementum nunc a leo imperdiet, nec elementum diam elementum. Etiam elementum euismod commodo. Proin eleifend eget quam ut efficitur. Mauris a accumsan mauris. Phasellus egestas et risus sit amet hendrerit. Nulla facilisi. Cras urna sem, vulputate sed condimentum a, posuere vel enim. Mauris a accumsan mauris. Phasellus egestas et risus sit amet hendrerit. Nulla facilisi. Cras urna sem, vulputate sed condimentum a, posuere vel enim.</p>
                        </Col>
                    </Row>
                    <div className='intro-row'>
                        <Row>
                            <Col lg={6}>
                                <div className='wrapper'>
                                    <h4 className='H2'>Who We Are</h4>
                                    <p>Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat. In posuere, nunc sit amet laoreet blandit, urna sapien imperdiet lectus, et molestie sem tortor quis dui. Donec molestie nisi iaculis sodales mollis.</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='wrapper'>
                                    <h4 className='H2'>Our Mission</h4>
                                    <p>Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat. In posuere, nunc sit amet laoreet blandit, urna sapien imperdiet lectus, et molestie sem tortor quis dui. Donec molestie nisi iaculis sodales mollis.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='reviews'>
                        <Row>
                            <Col lg={4}>
                                <p>Happy Clients vero Eos et accusamus et giusto odio dignissimos ducimus qui blanditi.</p>
                                <h1>290+</h1>
                            </Col>
                            <Col lg={4}>
                                <p>Years of Experience vero Eos et accusamus et giusto odio dignissimos ducimus qui blanditi.</p>
                                <h1>12+</h1>
                            </Col>
                            <Col lg={4}>
                                <p>Object Recognized vero Eos et accusamus et giusto odio dignissimos ducimus qui blanditi.</p>
                                <h1>30M+</h1>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Together