import React from 'react'
import { useState } from 'react'
import {  Row, Col, Tabs, Tab, Modal } from 'react-bootstrap'
import './Models.css'
import Details from './ContentEditTabs/Details'
import VideoEle from './ContentEditTabs/VideoEle'
import Visibility from './ContentEditTabs/Visibility'
// import {TiTick} from "react-icons/ti"
import TabButton from './TabButton'
const UploadVideo = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const [currentTab, setCurrentTab] = useState(0);
    const backTab = (event) => {
        if(currentTab !== 0){
            setCurrentTab((prev) => prev - 1)
          }else{
            // setCurrentTab(false)
          }
    }
    return(
        <>
            <div className='modalPage'>
                <div className="modal themeModal show" >
                    <Modal show={show} onHide={handleClose} className="medium-Model">
                    <div className='animatedTab'> 
                     <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Video 20221205071229 Soldier Major Tank</Modal.Title>
                        </Modal.Header>
                        <div className='videoModal'>
                            <Row className=''>
                                <Col>
                                <Tabs activeKey={currentTab} onClick={backTab} id="controlled-tab-example">
                                    <Tab eventKey={0} title={<TabButton/>} label="Item One"  disabled={currentTab === 0}>
                                        <Details />
                                    </Tab>
                                    <Tab eventKey={1} title={<TabButton/>} disabled={currentTab === 1}>
                                        <VideoEle/>
                                    </Tab>
                                    <Tab eventKey={2} title={<TabButton/>} disabled={currentTab === 2}>
                                        <Visibility/>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer className='pb-0'>
                            <div><button className="orange-btn text-uppercase mx-auto mt-0" disabled={currentTab === 2}
                            onClick={() => setCurrentTab((prev) => prev + 1)}>next</button></div>
                        </Modal.Footer>
                    </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default UploadVideo