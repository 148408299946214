import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import './Content.css'
import './VidoeAnalytics/VidoeAnalytics.css'
import { Table, Dropdown} from 'react-bootstrap'
import video1 from '../../../../assets/images/card5.png'
import { BiDotsVerticalRounded } from "react-icons/bi"
import { AiOutlineEdit, AiOutlineShareAlt} from "react-icons/ai"
import { RiDeleteBin4Line} from "react-icons/ri"
import { MdEdit } from "react-icons/md"
import { SiBookmeter } from "react-icons/si"
import { AiFillPlayCircle } from "react-icons/ai"
import UploadVideo from '../Models/UploadVideo'
import ContentEdit from '../Models/ContentEdit'
import EditDes from '../Models/ContentDropDownTab/EditDes'

const Content = () => {
    const [upload, setUpload] = useState(false)
    const [edit, setEdit] = useState(false)
    const [editDes, setEditDes] = useState(false)
    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader d-sm-flex justify-content-sm-between justify-content-center align-item-center'>
                    <h2 className='mb-sm-0 mb-3'>Content</h2><button class="orange-btn text-uppercase mt-0 " onClick={() => setUpload(!upload)}>UPLOAD VIDEO</button>
                </div>
                <div className='content px40'>
                    <div className='themeTable'>
                        <Table resposinve>
                            <thead>
                                <tr>
                                <th>
                                    <div className="form-check checkTable">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                </th>
                                <th>
                                <p>Video</p>
                                </th>
                                <th><p>Visibility</p></th>
                                <th><p>Restrictions</p></th>
                                <th><p>Date</p></th>
                                <th><p>Views</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="form-check checkTable">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='videoInfo'>
                                            <div className='videoImg'>
                                                <img className='img-fluid' src={video1} alt='Video'></img>
                                            </div>
                                            <div>
                                                <p>Video 20221205071229 Soldier</p>
                                                <p>Major Tank</p>
                                                <div className='videoIcons'>
                                                    <button className='iconBtn themeEffect' onClick={() => setEdit(!edit)}><MdEdit/></button>
                                                    <span><Link to='/vidoe-analytics' className='text-white themeEffect'><SiBookmeter/></Link></span>
                                                    <button className='iconBtn themeEffect'><AiFillPlayCircle/></button>
                                                    <Dropdown className='iconDD'>
                                                        <Dropdown.Toggle className='iconBtn iconDD-Btn' id="dropdown-basic">
                                                        <span><BiDotsVerticalRounded/></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => setEditDes(!editDes)}>
                                                            <span className='me-2'><AiOutlineEdit/></span>Edit Title & Description </Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2"><span className='me-2'><AiOutlineShareAlt/></span>Get Shareable Links</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3"><span className='me-2'><RiDeleteBin4Line/></span>Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td><p>Draft</p></td>
                                    <td><p>None</p></td>
                                    <td><p>09-12-2022</p></td>
                                    <td><p>12.43M</p></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="form-check checkTable">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='videoInfo'>
                                            <div className='videoImg'>
                                                <img className='img-fluid' src={video1} alt='Video'></img>
                                            </div>
                                            
                                            <div>
                                                <p>Video 20221205071229 Soldier</p>
                                                <p>Major Tank</p>
                                                <div className='videoIcons'>
                                                    <span><MdEdit/></span>
                                                    <span><SiBookmeter/></span>
                                                    <span><AiFillPlayCircle/></span>
                                                    <Dropdown className='iconDD'>
                                                        <Dropdown.Toggle className='iconBtn iconDD-Btn' id="dropdown-basic">
                                                        <span><BiDotsVerticalRounded/></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">
                                                            <span className='me-2'><AiOutlineEdit/></span>Edit Title & Description </Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2"><span className='me-2'><AiOutlineShareAlt/></span>Get Shareable Links</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3"><span className='me-2'><RiDeleteBin4Line/></span>Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td><p>Draft</p></td>
                                    <td><p>None</p></td>
                                    <td><p>09-12-2022</p></td>
                                    <td><p>12.43M</p></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="form-check checkTable">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='videoInfo'>
                                            <div className='videoImg'>
                                                <img className='img-fluid' src={video1} alt='Video'></img>
                                            </div>
                                            
                                            <div>
                                                <p>Video 20221205071229 Soldier</p>
                                                <p>Major Tank</p>
                                                <div className='videoIcons'>
                                                    <span><MdEdit/></span>
                                                    <span><SiBookmeter/></span>
                                                    <span><AiFillPlayCircle/></span>
                                                    <span><BiDotsVerticalRounded/></span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td><p>Draft</p></td>
                                    <td><p>None</p></td>
                                    <td><p>09-12-2022</p></td>
                                    <td><p>12.43M</p></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            {upload && <UploadVideo />}
            {edit && <ContentEdit />}
            {editDes && <EditDes />}
        </>
    )
}
export default Content