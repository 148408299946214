import React from 'react'
import '../Models.css'
import { Table} from 'react-bootstrap'
import roleImg from '../../../../../assets/images/roleImg.png'
const Permissions = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                <div className='general'>
                    <h4>Permissions for Elizabeth Fox</h4>
                    <p className='opacity-40 max-95'>Add or change permissions for this channel. For now, these permissions won't apply to some channel features and parts of BiiView.</p>
                    <div className='modalTable'>
                    <Table resposinve>
                        <thead>
                            <tr>
                                <th><p>name</p></th>
                                <th><p>role</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='permissionRoles'>
                                        <div className='roleProfile'>
                                            <figure className='roleImg'><img className='img-fluid' src={roleImg} alt='User Name'></img></figure>
                                            <div>
                                                <p className='userName mb-0'>Elizabeth Fox</p>
                                                <p className='roleEmail opacity-40'>elizabethfox@domain.com</p>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </td>
                                <td><p className='role'>owneer</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='permissionRoles'>
                                        <div className='roleProfile'>
                                            <figure className='roleImg'><img className='img-fluid' src={roleImg} alt='User Name'></img></figure>
                                            <div>
                                                <p className='userName mb-0'>Elizabeth Fox</p>
                                                <p className='roleEmail opacity-40'>elizabethfox@domain.com</p>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </td>
                                <td><p className='role'>owneer</p></td>
                            </tr>
                            
                        </tbody>
                    </Table>
                </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default Permissions