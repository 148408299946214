import React from 'react';
import '../Forms.css'
import {Container, Form } from 'react-bootstrap'
import formLogo from '../../../../assets/images/formLogo.png'
import { useNavigate, Link} from 'react-router-dom'


const ForgotPass = () => {
      const navigate = useNavigate();
      const navigateToLogin = () => {
          // 👇️ navigate to /Login
          navigate('/login');
        };
   
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                            <div className='wrapForm'>
                                <Form className='formStyle'>
                                    <div className='formHeader border-0'>
                                        <h2>Trouble logging in?</h2>
                                        <div className='d-flex align-items-end justify-content-start'><p   
                                        className='mb-0 outer-p text-start forgot'>Enter your email, phone, or username and we’ll send you a link to get back your account.</p></div>
                                    </div>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email, Phone, or Username"></input>
                                    </Form.Group>
                                    <div className='theme-capcha'>
                                        <button className='orange-btn text-uppercase end-mb' onClick={navigateToLogin}>SEND LOGIN LINK</button>
                                    </div>
                                    <div className='d-flex align-items-end justify-content-center'><p className='mb-0 outer-p link' onClick={navigateToLogin}>BACK TO LOGIN</p></div>
                                </Form>
                            </div>
                        </Container>
                    </div>
            </section>

        </>
    )
}
export default ForgotPass