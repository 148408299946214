import React from 'react'
import { Container } from 'react-bootstrap'

const PolicyBanner = () => {
    return (
        <>
            <section className='policy-banner-sec terms-sec'>
                <Container className='customBox'>
                    <h1>Privacy <span className='theme'>Policy</span>.</h1>
                    <p className='mb-0'>Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique nunc ut volutpat lectus.</p>
                </Container>
            </section>
        </>
    )
}

export default PolicyBanner