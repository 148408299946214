import React from 'react'
import '../Models.css'
import { Row, Col} from 'react-bootstrap'
import { TiArrowSortedDown } from "react-icons/ti"
const UploadAdvanceSettings = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                <div>
                    <div className='general mb-0'>
                    <div>
                        <h4>Automatic chapters</h4>
                        <p>Chapters and key moments make your video easier to watch. You can overwrite automatic suggestions by creating your own chapters in the video description.</p>
                    </div>
                        <div className='fileGroup'>
                        <div className='d-flex justify-content-start align-items-center mb-3'>
                            <div className="form-check checkTable me-2 px-0 modalCheck">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            </div>
                            <h4 className='check-H4 mb-0'>Allow automatic chapters and key moments</h4>
                        </div>
                        <div className='fileGroupList'>
                                <Row>
                                    <Col md={6}>
                                        <div className='modalSelect mb-2'>
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Select Visibility</option>
                                                <option value="1">Select residence of country1</option>
                                                <option value="2">Select residence of country2</option>
                                                <option value="3">Select residence of country2</option>
                                            </select>
                                            <span><TiArrowSortedDown/></span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='modalSelect mb-2'>
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Select Visibility</option>
                                                <option value="1">Select residence of country1</option>
                                                <option value="2">Select residence of country2</option>
                                                <option value="3">Select residence of country2</option>
                                            </select>
                                            <span><TiArrowSortedDown/></span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='fileGroupList'>
                            <h4>Language and captions certification</h4>
                                <Row>
                                    <Col md={6}>
                                        <div className='modalSelect mb-2'>
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Select Visibility</option>
                                                <option value="1">Select residence of country1</option>
                                                <option value="2">Select residence of country2</option>
                                                <option value="3">Select residence of country2</option>
                                            </select>
                                            <span><TiArrowSortedDown/></span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='modalSelect mb-2'>
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Select Visibility</option>
                                                <option value="1">Select residence of country1</option>
                                                <option value="2">Select residence of country2</option>
                                                <option value="3">Select residence of country2</option>
                                            </select>
                                            <span><TiArrowSortedDown/></span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='modalSelect mb-2'>
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Select Visibility</option>
                                                <option value="1">Select residence of country1</option>
                                                <option value="2">Select residence of country2</option>
                                                <option value="3">Select residence of country2</option>
                                            </select>
                                            <span><TiArrowSortedDown/></span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default UploadAdvanceSettings