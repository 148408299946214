import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import './Models.css'
import Modal from 'react-bootstrap/Modal'
import card1 from '../../../../assets/images/card1.png'
const UploadVideo = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    return(
        <>
            <div className='modalPage'>
                <div className="modal themeModal show" >
                    <Modal show={show} onHide={handleClose} className='narrowModel'>
                        <Modal.Header closeButton>
                        <Modal.Title className='mb-0'>Video 20221205071229 Soldier Major Tank</Modal.Title>
                        </Modal.Header>
                        <Modal.Body class>
                            <div className='seletFile modalBox'>
                                <h4 className='modal-H4'>Details</h4>
                                <div className='fileGroup'>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'>Title (required)</p>
                                        <div className='fileBlock'>
                                            <p>Video 20221205071229 Soldier Major Tank</p>
                                        </div>
                                    </div>
                                    <div className='fileGroupList'>
                                        <p className='fileLabel'>Description</p>
                                        <Form.Group className='w-100' as="textarea" rows={3} placeholder='Tell us something about your video….'>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='thumbnail'>
                                    <h4 className='modal-H4'>Thumbnails</h4>
                                    <p className='fileLabel'>Upload video thumbnail.</p>
                                    <div className='thumbnailImg'>
                                        <div className='videoImg'><img className='img-fluid' src={card1} alt='biiview'></img></div>
                                        <div className='videoImg'><img className='img-fluid' src={card1} alt='biiview'></img></div>
                                        <div className='videoImg'></div>
                                        <div className='videoImg'></div>
                                    </div>
                                </div>
                                
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='pb-0'>
                                <div><Link to='/content' onClick={handleClose} class="theme-btn orange-btn text-uppercase mx-auto mt-0">Save</Link></div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default UploadVideo