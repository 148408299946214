import React from 'react'
import { useState , useRef} from 'react'
import { Link } from 'react-router-dom'
import '../Models.css'
import { Accordion, Row, Col } from 'react-bootstrap'
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"
import { BsQuestionCircle } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
const FeatureEligibility = () => {
const [show, setShow] = useState();
const ref = useRef(null)
const toggleShow = event => {
    console.log(ref.current.id);
    const id = event.target.getAttribute("id");
    console.log(id);
    setShow(!show);
  };
 
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                <div className='general'>
                    <p>You can manage your access to more features here. Intermediate and advanced features require additional verification which helps us keep the YouTube community safe for everyone.</p>
                    <div className='faqTabs'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header ref={ref} id='1' onClick={toggleShow}>
                                    <span className='w-100'><h4>1. Standard features</h4>
                                    <p>Upload videos, create playlists and add collaborators and new videos to play...</p></span>
                                    <div>
                                    <span  className="accordionArrow">{show ? <TiArrowSortedDown/> : <TiArrowSortedUp/> }</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                 <Row>
                                    <Col md={7} className='mb-md-0 mb-3'>
                                        <div>
                                            <div><p className='faqP'>Get your youtube channel started abd begin growing your audiance with these <Link className='blueLink opacity-100 text-lowercase d-inline'>features</Link></p></div>
                                            <ul className='faqList'>
                                                <li><p>Video Uploads</p></li>
                                                <li><p>Playlist creation</p></li>
                                                <li><p>Add collaborators to playlist</p></li>
                                                <li><p>Add new videos to existing playlist</p></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className='faq-LeftBorder'>
                                            <h4>Requirements</h4>
                                            <p className='faqP'>To get standard features, you need</p>
                                            <ul className='check-UL'>
                                                <li><span  className='checkIcon'><BiCheck /></span><div><h4>Channel is free of active Community guidlines strikes</h4></div></li>
                                            </ul>
                                        </div>
                                    </Col>
                                 </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header ref={ref} id='2' onClick={toggleShow}>
                                <span className='w-100'><h4>2. Intermediate features</h4>
                                    <p>Upload videos, create playlists and add collaborators and new videos to play...</p></span>
                                    <div>
                                    <span  className="accordionArrow">{show ? <TiArrowSortedDown/> : <TiArrowSortedUp/> }</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={7} className='mb-md-0 mb-3'>
                                            <div>
                                                <div><p className='faqP'>Get your youtube channel started abd begin growing your audiance with these <Link className='blueLink opacity-100 text-lowercase d-inline'>features</Link></p></div>
                                                <ul className='faqList'>
                                                    <li><p>Video Uploads</p></li>
                                                    <li><p>Playlist creation</p></li>
                                                    <li><p>Add collaborators to playlist</p></li>
                                                    <li><p>Add new videos to existing playlist</p></li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div className='faq-LeftBorder'>
                                                <h4>Requirements</h4>
                                                <p className='faqP'>To get standard features, you need</p>
                                                <ul className='check-UL'>
                                                    <li><span className='checkIcon'><BiCheck /></span><div><h4>Standard features enabled</h4></div></li>
                                                </ul>
                                                <div className='andLine'><h4>AND</h4></div>
                                                <ul>
                                                    <li><div className='d-flex justify-content-center align-items-center'>
                                                        <p className='mb-0 me-2 faqP opacity-100'>Verify Phone Number</p><span><BsQuestionCircle /></span>
                                                    </div></li>
                                                </ul>
                                                <button class="orange-btn text-capitalize mx-auto mt-0">verify phone number</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header ref={ref} id='3' onClick={toggleShow}>
                                <span className='w-100'><h4>3. Advanced features</h4>
                                    <p>Upload videos, create playlists and add collaborators and new videos to play...</p></span>
                                    <div>
                                    <span  className="accordionArrow">{show ? <TiArrowSortedDown/> : <TiArrowSortedUp/> }</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={7} className='mb-md-0 mb-3'>
                                            <div>
                                                <div><p className='faqP'>Get your youtube channel started abd begin growing your audiance with these <Link className='blueLink opacity-100 text-lowercase d-inline'>features</Link></p></div>
                                                <ul className='faqList'>
                                                    <li><p>Create more live streams daily</p></li>
                                                    <li><p>Upload more videos daily</p></li>
                                                    <li><p>Create more Shorts daily</p></li>
                                                    <li><p>External links in video description</p></li>
                                                    <li><p>Appealing Content ID claims</p></li>
                                                    <li><p>Eligibility to apply monetization*</p></li>
                                                </ul>
                                                <div><p className='faqP'>Some featurs have additional requirements. <Link className='blueLink opacity-100 text-lowercase d-inline'>Learn more</Link></p></div>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div className='faq-LeftBorder'>
                                                <h4>Requirements</h4>
                                                <p className='faqP'>To get advanced features, you need</p>
                                                <ul className='check-UL'>
                                                    <li><span  className='checkIcon'><BiCheck /></span><div><h4>Intermediate features enabled</h4></div></li>
                                                </ul>
                                                <div className='andLine'><h4>AND 1 OF THESE</h4></div>
                                                <ul>
                                                    <li><div className='d-flex align-items-center'>
                                                        <p className='mb-0 me-2 faqP opacity-100'>Video verification</p><span><BsQuestionCircle /></span>
                                                    </div></li>
                                                    <li><div className='d-flex align-items-center'>
                                                        <p className='mb-0 me-2 faqP opacity-100'>Valid ID</p><span><BsQuestionCircle /></span>
                                                    </div></li>
                                                </ul>
                                                <ul className='p-0 mb-3'>
                                                    <li className='d-flex align-items-center'><span className='checkIcon'><BiCheck /></span><div><p className='mb-0 me-2 faqP opacity-100'>Channel history</p></div></li>
                                                </ul>
                                                <button class="grey-btn text-uppercase mx-auto">access features</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default FeatureEligibility