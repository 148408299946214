import React from 'react'
import '../Models.css'
import { Form } from 'react-bootstrap'
import { TiArrowSortedDown } from "react-icons/ti"
const UploadBasicInfo  = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                <div>
                    <div className='general mb-0'>
                        <div className='fileGroup'>
                            <div className='fileGroupList'>
                                <p className='fileLabel'>Title (required)</p>
                                <div className='fileBlock'>
                                    <p>Video 20221205071229 Soldier Major Tank</p>
                                </div>
                            </div>
                            <div className='fileGroupList'>
                                <p className='fileLabel'>Description</p>
                                <Form.Group className='w-100' as="textarea" rows={3} placeholder='Tell us something about your video….'>
                                </Form.Group>
                            </div>
                            <div className='fileGroupList'>
                                <div className='modalSelect mb-2'>
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected>Select Visibility</option>
                                        <option value="1">Select residence of country1</option>
                                        <option value="2">Select residence of country2</option>
                                        <option value="3">Select residence of country2</option>
                                    </select>
                                    <span><TiArrowSortedDown/></span>
                                </div>
                            </div>
                            <div className='fileGroupList'>
                                <Form.Group className='w-100' as="textarea" rows={3} placeholder='Add tag'>
                                </Form.Group>
                            </div>
                            <p>Enter comma-seperated values</p>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default UploadBasicInfo 