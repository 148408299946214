import React from 'react'
import './siteTabs.css'
// import '../Sidebar/DashboardSidebar.css'
import '../MemberSidebar/MemberDashboardSidebar.css'
import { Row, Col,Badge } from 'react-bootstrap'
import ReactECharts from 'echarts-for-react'

const ContentTab = () => {
    const colors = ['#FD6F21'];
    const options = {
        color: colors,
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            color: colors[1]
          }
        ]
      };
    return(
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                <div className='analyticGraph'>
                    <div className='analyticsBar'>
                        <div className='analyticsBarBox'>
                            <span>Watch Time (Hours)</span>
                            <h4>32412</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                    </div>
                    <ReactECharts option={options} />
                </div>
                <Row className='tabBox'>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes mb-0'>
                                        <h4>How views find this video</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                        <div className='badgeFlex'>
                                            <Badge bg="secondary"  pill >Overall</Badge>
                                            <Badge bg="secondary"  pill >External</Badge>
                                            <Badge bg="secondary"  pill >BiiView search</Badge>
                                            <Badge bg="secondary"  pill >Suggested videos</Badge>
                                        </div>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top videos</h4>
                                        <p className='md-P mb-0'>Views - Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough traffic data to show this report</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>How viewers find your videos</h4>
                                        <p className='md-P mb-0'>Views · Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Not enough impressions data to show this report.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                        <div className='box tabBox'>
                                <div className='analyticDetailBox'>
                                    <div className='likes'>
                                        <h4>Top Remixed</h4>
                                        <p className='md-P mb-0'>Your content used to create Shorts · Last 28 days</p>
                                    </div>
                                    <p  className='md-P mb-lg-3 mb-2'>Nothing to show for these dates</p>
                                    <div>
                                        <button  className='link orangeLink text-uppercase'>see more</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default ContentTab