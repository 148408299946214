import React from 'react'
import { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import '../../TabComponents/siteTabs.css'
import { Row, Col, Dropdown} from 'react-bootstrap'
import { IoCaretDownSharp } from "react-icons/io5"
import AnalyticsContent from '../../TabComponents/AnalyticsContent'
import Audiance from '../../TabComponents/Audiance'
import Reach from '../../TabComponents/Reach'
import IdentifiedObjects from '../../TabComponents/IdentifiedObjects'
import ReactECharts from 'echarts-for-react'

const VidoeAnalytics = () => {
    const [menu, setMenu] =useState(false)
    const [menu2, setMenu2] =useState(false)
    const colors = ['#FD6F21'];
    const options = {
        color: colors,
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            color: colors[1]
          }
        ]
      }

    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader'>
                    <h2>Video analytics</h2>
                </div>
                <div className='videoAnalytics themeAnalytics'>
                    <Tabs>
                        <div className='tabFlex'>
                            <TabList className='border-0 mb-0'>
                            <Tab>Overview</Tab>
                            <Tab>Reach</Tab>
                            <Tab>Engagement</Tab>
                            <Tab>Audience</Tab>
                            <Tab>Identified Objects</Tab>
                            </TabList>
                            <div className='realtimeBox mb-0 border-0'>
                                <div>
                                <p className='me-1 mb-0'>Nov 18 – Dec 15, 2022</p>
                                    <div className='searchByDays align-items-center'>
                                        <div><h4 className='me-1 mb-0'>Last 28 days</h4></div>
                                            <Dropdown className='iconDD barDD'>
                                                <Dropdown.Toggle className='iconBtn iconDD-Btn' id="dropdown-basic" onChange={() => setMenu}>
                                                    <span onClick={() => setMenu2(!menu2)} className={menu2 ? 'menuRotate' : 'menuOriginal' }><IoCaretDownSharp/></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                    <span className='me-2'></span>24 Hours</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2"><span className='me-2'></span>7 Days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><span className='me-2'></span>15 Days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><span className='me-2'></span>30 Days</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3"><span className='me-2'></span>Custom</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TabPanel>
                            <Row>
                                <Col lg={8} className=''>
                                    <div className='analyticGraph'>
                                        <div className='analyticsBar'>
                                            <div className='analyticsBarBox'>
                                                <span>Views</span>
                                                <h4>32412</h4>
                                            </div>
                                            <div className='analyticsBarBox'>
                                                <span>Watch Time (Hours)</span>
                                                <h4>38:34</h4>
                                            </div>
                                            <div className='analyticsBarBox'>
                                                <span>Object Recognized</span>
                                                <h4>124</h4>
                                            </div>
                                        </div>
                                        <ReactECharts option={options} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className='box h-auto'>
                                        <div className='realtimeBox'>
                                            <div>
                                                <h5>Realtime</h5>
                                                <p>Updating live</p>
                                            </div>
                                            <div>
                                            <h5>Realtime</h5>
                                            <div className='searchByDays align-items-center'>
                                                <div><p className='md-16 me-5 mb-0'>Views - Last 60 minutes</p></div>
                                                <Dropdown className='iconDD barDD'>
                                                    <Dropdown.Toggle className='iconBtn iconDD-Btn' id="dropdown-basic" onChange={() => setMenu}>
                                                        <span onClick={() => setMenu(!menu)} className={menu ? 'menuRotate' : 'menuOriginal' }><IoCaretDownSharp/></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                        <span className='me-2'></span>24 Hours</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2"><span className='me-2'></span>7 Days</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3"><span className='me-2'></span>15 Days</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3"><span className='me-2'></span>30 Days</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3"><span className='me-2'></span>Custom</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='realtimeBox-btm'>
                                            <p className='mb-0'>-60 (min)</p>
                                            <p className='mb-0'>Now</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel><Reach/></TabPanel>
                        <TabPanel><AnalyticsContent/></TabPanel>
                        <TabPanel><Audiance/></TabPanel>
                        <TabPanel><IdentifiedObjects/></TabPanel>

                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default VidoeAnalytics