import React from 'react'
import { useState } from 'react'
import './Models.css';
import Modal from 'react-bootstrap/Modal'
import { HiUpload } from "react-icons/hi"
import SelectFile from './SelectFile'

const UploadVideo = () => {
    const [show, setShow] = useState(true)
    const handleClose = () => setShow(false)
    const [selectFile, setSelectFile] = useState(false)
    const handlesss = () => {
        setSelectFile(!selectFile)
        setShow(false)
    }

    return(
        <>
            <div className='modalPage'>
                {/* <button variant="primary" onClick={handleShow}>
                    Launch demo modal
                </button> */}
                <div className="modal themeModal show" >
                    <Modal className='uploadvideo' show={show} onHide={handleClose} id='wrapper'>
                        <div class="scrollbar" id="style-default">
                            <div class="force-overflow"></div>
                            </div>
                            <Modal.Header closeButton>
                            <Modal.Title className='mb-0'>Upload Video</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='wrapUpload text-center'>
                                    <div className='uploadBtn'><HiUpload/></div>
                                    <h4 className='modal-H4'>Drag & drop video files to upload</h4>
                                    <p className='mb-0 modalLightP opacity-40'>Your video will be private until you publish them</p>
                                    <div><button class="orange-btn text-uppercase mx-auto" onClick={handlesss}>SELECT FILE</button></div>
                                </div>
                            </Modal.Body>
                    </Modal>
                </div>
            </div>
            {selectFile && <SelectFile />}
        </>
    )
}
export default UploadVideo