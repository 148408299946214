import {useState} from 'react';
import './Header.css'
import {Container, Nav, Navbar} from 'react-bootstrap'
import logo from '../../../assets/images/logo.png'
import {  FaUserAlt, FaSearch} from "react-icons/fa";
import { GrFormClose} from "react-icons/gr";
import { Link } from "react-router-dom";


const Header = () => {
    // SearchBar
    const [search, setSerach] = useState(true)
    const [bar, setBar] = useState(false)
    return(
        <>
            <div className='header'>
                <Navbar expand="lg">
                    <Container fluid className='customBox-header'>
                        <div className='wrapHeaderBar'>
                            <div className='nav-flex'>
                                <Navbar.Brand href="/">
                                    <img className='img-fluid' src={logo} alt='BiiView'></img>
                                </Navbar.Brand>
                                <div className='nav-right-bar'>
                                    <div className='wrapSearch'>
                                            <span className='navBtns' onClick={()=>setSerach(!search)}><FaSearch /> </span>
                                            {
                                                 search ?
                                                <span></span>  :
                                                <span className='openSearch' >
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" /> 
                                                    <span className='searchIcon' onClick={()=>setSerach(!search)}><GrFormClose /></span>
                                                </span> 
                                            }
                                    </div>
                                    <div>
                                        <span className='navBtns'>
                                            <FaUserAlt />
                                        </span>
                                    </div>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                                        <span className='navBtns'  onClick={()=>setBar(!bar)} >
                                            {/* <FaBars /> */}
                                            <div className={bar ? 'faBar faCross' : 'faBar'} >
                                                <span></span><span></span><span></span>
                                            </div>
                                        </span>
                                    </Navbar.Toggle>
                                </div>
                            </div>
                            <Navbar.Collapse>
                                <Nav className="ms-auto">
                                    <Link className="nav-link" to="/">Home</Link>
                                    <Link className="nav-link" to="/about">about</Link>
                                    <Link className="nav-link" to="/blogs">Blog</Link>
                                    <Link className="nav-link" to="/contact">contact</Link>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}
export default Header