import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Information = () => {
    return (
        <>
            <section className='information-sec pb-0'>
                <Container className='customBox'>
                    <h1>Information we <span className='theme'>Collect</span>.</h1>
                    <p className='para'>Proin ex ipsum, facilisis id tincidunt sed, vulputate in lacus. Donec pharetra faucibus leo, vitae vestibulum leo scelerisque eu. Nam enim dolor, porta at ex ut, eleifend tristique metus. Integer sit amet auctor ligula, vel dictum orci. Nam feugiat interdum nisl. Ut id velit ac ante maximus gravida.</p>
                    <Row>
                        <Col lg={6}>
                            <div className='wrapper'>
                                <h2 className='H2'>Log data</h2>
                                <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.</p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='wrapper'>
                                <h2 className='H2'>Device data</h2>
                                <p>Quisque orci justo, aliquet ac tristique in, euismod et lectus. Nunc eu enim quam. Curabitur volutpat dolor at cursus laoreet. Aliquam vitae mi quis lorem condimentum finibus. Maecenas dignissim, felis ut convallis dapibus, libero sem condimentum tellus, id feugiat magna tellus a justo.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className='details'>
                        <h4>Collection and use of information</h4>
                        <p className='para1'>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellenteSed cursus, erat quis dignissim congue, purus dui bibendum mauris,</p>
                        <p>Joncus tortor felis eu magna. Donec a elementum massa. Nam iaculis ligula nulla, at ultrices massa consectetur id. Nullam quis posuere arcu, in mattis ligula. Aliquam vel bibendum nibh. Aenean quis erat lobortis odio bibendum.</p>
                        <div className='ul-list'>
                            <ul>
                                <li>Aliquam erat volutpat. Integer sit amet laoreet eros</li>
                                <li>Etiam feugiat sit amet urna eget efficitur</li>
                                <li>Rutrum tincidunt dui hendrerit wam lacus eli faucibus id efficitur</li>
                                <li>Hincidunt et est isque eget lacus non orci ultricies fringilla</li>
                            </ul>
                        </div>
                        <div className='cookie-policy'>
                            <h2 className='H2'>Cookie Policy</h2>
                            <p className='mb-0'>Anable Google analytics tracking:</p>
                        </div>
                        <p className='para1'>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellenteSed cursus, erat quis dignissim congue, purus dui bibendum mauris,</p>
                        <p>Joncus tortor felis eu magna. Donec a elementum massa. Nam iaculis ligula nulla, at ultrices massa consectetur id. Nullam quis posuere arcu, in mattis ligula. Aliquam vel bibendum nibh. Aenean quis erat lobortis odio bibendum.</p>
                        <h4>What is a cookie?</h4>
                        <p>Curabitur nibh enim, consectetur dapibus nibh et, pharetra gravida diam. Sed efficitur sollicitudin ante at euismod. Maecenas quis porta nunc, et ultrices elit. Nulla pulvinar leo a volutpat consequat. Proin pretium convallis tincidunt. Donec vestibulum, nisi a euismod pulvinar, justo orci viverra elit, vel finibus lectus est eget risus. Maecenas magna Leo.</p>
                        <h4>How you can control or opt-out of cookies</h4>
                        <p className='mb-0'>Sed quis scelerisque ante. Duis neque arcu, egestas quis tempor in, tincidunt id mauris. Maecenas tincidunt porta nibh, sit amet semper elit vulputate vel. Proin tristique efficitur tempus. Pellentesque ac gravida elit. Donec viverra est malesuada nisi posuere ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis. egestas. Mauris in lobortis elit. Fusce porttitor sodales massa, et suscipit ligula rhoncus vitae. Cras dictum nunc et condimentum ullamcorper. Pellentesque scelerisque nibh eleifend tortor semper, nec facilisis mauris finibus. Integer porttitor auctor eros nec pellentesque.</p>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Information