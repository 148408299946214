import React, { useEffect, useState } from 'react';
import MemberSideBar from '../Dashboard/MemberSidebar/MemberDashboardSidebar'

const MemberDashboard = ({ title, children }) => {

  const [dashboardBar, setDashboardBar] = useState(true)

  useEffect(() => {
    if (title)
      document.title = title;
    else
      document.title = "BIIVIEW";
  }, [title]);

  return (
    <>
    <div className='dashboardBody'>
      <MemberSideBar dashboardBar={dashboardBar} setDashboardBar={setDashboardBar} />
      <div className={dashboardBar ? 'dashboard-sitePages ddfdfd dashboard-main-width' : ' right dashboard-sitePages sitePages-width'}>
        {children}
      </div>
    </div>

    </>
  )
}

export default MemberDashboard