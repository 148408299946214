import React from 'react'
import '../Models.css'
import { TiArrowSortedDown } from "react-icons/ti"
const BasicInfo = () => {
    return(
        <>
            <div className='modalPage h-100'>
                <div className="themeModal settingFlex" >
                <div>
                    <div className='general'>
                        <div className='modalSelect mb-2'>
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Select residence of country</option>
                                <option value="1">Select residence of country1</option>
                                <option value="2">Select residence of country2</option>
                                <option value="3">Select residence of country2</option>
                            </select>
                            <span><TiArrowSortedDown/></span>
                        </div>
                        <p>Choose the country where you’re currentlybased.</p>
                    </div>
                    <div className='general mb-0'>
                        <div className='mb-2'>
                            <div class="innerTab-input-group">
                                <input type="text" class="form-control" placeholder="Add keywords" aria-label="Username" />
                            </div>
                        </div>
                        <p>Enter comma-separated values</p>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default BasicInfo